import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./common/authSlice.js";
import customerReducer from "./components/Customers/customerSlice.js";
import usersReducer from "./components/Users/usersSlice.js";
import groupsReducer from "./components/Groups/groupsSlice.js";
import roleReducer from "./common/roleSlice.js";
import foldersReducer from "./components/Folders/foldersSlice.js";
import logger from 'redux-logger';

export default configureStore({
  reducer: {
    auth: authReducer,
    customers: customerReducer,
    role: roleReducer,
    users: usersReducer,
    folders: foldersReducer,
    groups: groupsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
