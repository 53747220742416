import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmModal(props) {
  
  const {title, description, verificationString, onSubmitHandler, open, setOpen, placeholderLabel, buttonLoading} = props;
    const [inputVerificationString, setInputVerificationString] = React.useState("");
  const handleClose = () => setOpen(false);
  return (
    <div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2" color="#1a8aff">
           {title}
          </Typography>
          <Typography id="modal-modal-description" variant="h6" sx={{ mt: 1, mb:1 }}>
            {description}
          </Typography>
          <TextField 
          fullWidth
          placeholder={placeholderLabel}
          name="verificationText"
          onChange={(e) =>{
            setInputVerificationString(e.target.value);
          }}
          />
          {/* <div style={{display:"flex", flexDirection:"row-reverse", marginTop:"15px"}}>
            <Button  disabled={inputVerificationString !== verificationString} variant='contained' onClick={onSubmitHandler}>Confirm</Button>
          </div> */}

          <div style={{display:"flex", flexDirection:"row-reverse", marginTop:"15px"}}>
            <LoadingButton loading={buttonLoading} disabled={inputVerificationString !== verificationString} variant='contained' onClick={onSubmitHandler}>Confirm</LoadingButton>
          </div>
        </Box>
    
      </Modal>
  
    </div>
  );
}