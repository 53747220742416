import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getCustomers = createAsyncThunk(
  "getCustomers",
  async (rejectWithValue) => {
    try {
      const response = await new HTTPClient({
        endpoint: "/customers?allCustomers=true",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      console.log("Error");
      return rejectWithValue(error.response.data)
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (customer, { rejectWithValue }) => {
    try {
      const response = await new HTTPClient({
        endpoint: `/customers/${customer.code}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      console.log("Error");
      return rejectWithValue(error.response.data)
    }
  }
);


export const createCustomer = createAsyncThunk(
  "createCustomer",
  async ({ deploymentCode, payload }, { rejectWithValue }) => {
    console.log(deploymentCode);
    console.log(payload);
    try {
      const response = await new HTTPClient({
        endpoint: `/deployments/${deploymentCode}/customer`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data)
    }
  }
);

export const convertCustomer = createAsyncThunk(
  "convertCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await new HTTPClient({
        endpoint: `/deployments/${payload.deploymentCode}/customer/${payload.code}/cascade`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data)
    }
  }
);


export const getCustomerDeploymentCodes = createAsyncThunk(
  "getCustomerDeploymentCodes",
  async (rejectWithValue) => {
    try {
      const response = await new HTTPClient({
        endpoint: "/customers/deployments",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      console.log("Error");
      return rejectWithValue(error.response.data)
    }
  }
);


export const initialState = {
  customers: [],
  loadingCustomers: null,
  error: "",
  loadingDeploymentCodes: null,
  deploymentCodes: [],
  errorDeploymentCode: null,

  creatingCustomer: null,
  createCustomerSuccess: null,
  createCustomerError: null,
  deleteCustomerError: null,
  deleteCustomerSuccess: null,
  loadingDelete: null,
  loadingConvert: null,
  convertError: null,
  convertSuccess: null
};

export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    reset: (state, action) => {
      state.createCustomerSuccess = null;
      state.createCustomerError = null;
      state.deleteCustomerError = null;
      state.deleteCustomerSuccess = null;
      state.error = "";
      state.errorDeploymentCode = null;
      state.convertError = null;
      state.convertSuccess = null;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    }
  },
  extraReducers: {
    [getCustomers.pending]: (state, action) => {
      state.loadingCustomers = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getCustomers.fulfilled]: (state, action) => {

      state.currentRequestId = null;
      let data = []
      let count = 0;
      if (action.payload) {
        count = 0;
        action.payload.map((a) => {
          data.push({ ...a, id: count });
          count++;
        })
      }
      state.customers = action.payload ? data : [];
      state.loadingCustomers = false;
    },
    [getCustomers.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingCustomers = false;
      state.currentRequestId = null;
    },
    [deleteCustomer.pending]: (state, action) => {
      state.loadingDelete = true;
      state.currentRequestId = action.meta.requestId;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingDelete = false;
      state.deleteCustomerSuccess = true;
    },
    [deleteCustomer.rejected]: (state, action) => {
      state.deleteCustomerError = action.payload;
      state.loadingDelete = false;
      state.currentRequestId = null;
    },


    [getCustomerDeploymentCodes.pending]: (state, action) => {
      state.loadingDeploymentCodes = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getCustomerDeploymentCodes.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.deploymentCodes = action.payload ? action.payload.map((a) => {
        return { ...a, name: a.code, value: a.code }
      }) : [];
      state.loadingDeploymentCodes = false;
    },
    [getCustomerDeploymentCodes.rejected]: (state, action) => {
      state.errorDeploymentCode = action.payload;
      state.loadingDeploymentCodes = false;
      state.currentRequestId = null;
    },

    [createCustomer.pending]: (state, action) => {
      state.creatingCustomer = true;
      state.currentRequestId = action.meta.requestId;
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.creatingCustomer = false;
      state.createCustomerSuccess = true;
    },
    [createCustomer.rejected]: (state, action) => {
      state.createCustomerError = action.payload;
      state.creatingCustomer = false;
      state.currentRequestId = null;
    },
    [convertCustomer.pending]: (state, action) => {
      state.loadingConvert = true;
      state.currentRequestId = action.meta.requestId;
    },
    [convertCustomer.fulfilled]: (state, action) => {
      state.loadingConvert = false;
      state.convertSuccess = true;
    },
    [convertCustomer.rejected]: (state, action) => {
      state.convertError = action.payload;
      state.loadingConvert = false;
      state.currentRequestId = null;
    },

  },
});

export const { reset, setCustomers } = customerSlice.actions;

export default customerSlice.reducer;
