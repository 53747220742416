const local_storage = localStorage;
const session_storage = sessionStorage;

export const setItem = (key, value) => {
    local_storage.setItem(key, value);
};

export const fetchItem = (key) => {
  return local_storage.getItem(key);
};


export const setSessionItem = (key, value) => {
    session_storage.setItem(key, value);
};

export const fetchSessionItem = (key) => {
  return session_storage.getItem(key);
};
