import axios from "axios";
import { HTTP_METHODS, CORE_API_URL } from "../constants";
import { fetchSessionItem } from "../../storage";
import { useNavigate } from "react-router-dom";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

const BASE_URL = CORE_API_URL;


export default class HTTPClient {

  constructor(config) {
    this.config = {
      endpoint: config.params ? this.generateURL(config.endpoint, config.params) : config.endpoint,
      method: config.method || HTTP_METHODS.GET,
      data: config.data,
      signal: config.signal,
      headers: {
        ...config.headers,
        "Content-Type": "application/json",
      },
    };
  }

  callAuthorizedAPI = async () => {

    const access_token = fetchSessionItem("access_token");
    const expires_on = fetchSessionItem("expires_on");
    const token_type = fetchSessionItem("token_type");


    const currentTime = Math.floor(Date.now() / 1000);
   
    if (currentTime > parseInt(expires_on) || !access_token) {
      const navigate = useNavigate();
      navigate("/login");
    }

    const token = token_type + " " + access_token;
    return new Promise((resolve, reject) => {
      axios(BASE_URL + this.config.endpoint, { ...this.config, headers: { ...this.config.headers, 'Authorization': token } })
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  callUnauthorizedAPI = async () => {
    return new Promise((resolve, reject) => {
      axios(BASE_URL + this.config.endpoint, this.config)
        .then((r) => {
          resolve(r);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  generateURL = (endpoint, queryParams) => {
    const URL = endpoint + "?";
    const params = [];
    Object.entries(queryParams).forEach(([key, value]) => {
      params.push(`${key}=${value}`);
    });
    return URL + params.join("&");
  };
}





