import { ButtonBase, Grid, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import * as XLSX from "xlsx";
import { Link } from 'react-router-dom';

const ImportUsers = (props) => {


    const [data, setData] = useState(null);

    const { usersData, setUsersData, setTabValue } = props;

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setData(e.target.result);
            }
        } else {
            console.log("File not Selected");
        }
    }

    const handleConvert = () => {
        if (data == null) return;
        const workBook = XLSX.read(data, { type: 'buffer' });
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        const d = XLSX.utils.sheet_to_json(workSheet);

        const data_to_append = d.map((a) => {
            return {
                userName: a.UserName,
                firstName: a.FirstName,
                lastName: a.LastName,
                displayName: a.DisplayName,
                phoneNo: a.PhoneNo
            }
        })
        const new_data = [...data_to_append, ...usersData];
        setUsersData(new_data.map((a, index) => {
            return { ...a, id: index }
        }));
        setTabValue("Add Users");
    }

    const handleExport = () =>{
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet([{UserName:"", FirstName:"", LastName:"", DisplayName:"", PhoneNo:""}]);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet_1");
        XLSX.writeFile(wb, "UsersTemplate.xlsx");
    }
    
    return (
        <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>

            <Grid item xs={5} md={5} lg={5} xl={5} >
                <Typography variant="p" color="#1a8aff">Choose XLSX File below to import users.</Typography>
                <Tooltip title={"Click to download XLSX template"}>
                <Link to="#" onClick={()=>{
                    handleExport();
                }} style={{marginLeft:"10px"}}>Download</Link>
               </Tooltip>
            </Grid>

            <Grid item xs={7} md={7} lg={7} xl={7}>

            </Grid>

            <Grid item xs={4} md={4} lg={4} xl={4}>
                <TextField
                    type="file"
                    name="file"
                    required
                    fullWidth
                    variant='outlined'
                    onChange={handleFile}
                    data-testid={"Choose-file"}
                />
            </Grid>

            <Grid item xs={8} md={8} lg={8} xl={8}></Grid>

            <Grid item xs={4} md={4} lg={4} xl={4} style={{ display: 'flex', flexDirection: "row-reverse" }}>
                <Button data-testid={"submit-spreadsheet-button"} onClick={handleConvert} style={{ backgroundColor: "#1a8aff", color: "white" }}>Submit</Button>
            </Grid>

        </Grid>
    )
}

export default ImportUsers