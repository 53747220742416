import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ViewPointLogo from "../viewpoint-logo-full-color.svg";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { AD_LOGIN_URL, CALLBACK_REDIRECT_URI } from "../common/constants";
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../common/contexts/AuthContext';

const theme = createTheme();


export default function SignInError() {
  const { state: { isAuthenticated } } = useAuthContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    const redirect_url = AD_LOGIN_URL + "&redirect_uri=" + encodeURIComponent(CALLBACK_REDIRECT_URI) + "&prompt=select_account";
    window.location.assign(redirect_url);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (sessionStorage.getItem("role") === "Tenant Admins") {
        navigate("/home");
      } else {
        navigate("/users");
      }
    }
  }, [isAuthenticated])


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={ViewPointLogo} alt="ViewPointLogo" style={{ paddingBottom: "20px" }} />
            <Typography component="h3" variant="h5" color="red">
              An Error Occured, Please check User Credentials and Permissions
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#0063a3" }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0063a3',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}