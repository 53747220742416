import { TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { checkMobile, composeValidators } from "../common/validators";

const InputField = (props) => {
  const { onChange, label, value, defaultValue, datatestid, type, size, disabled, InputProps, extraLabel, helperText, error } = props;

  const [isValid, setIsValid] = useState(false);
  const [validationText, setValidationText] = useState("");

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <div style={{ padding: "5px", display: "flex", flexDirection: "row" }}>
        <Typography variant="p" color="#1a8aff">{label}</Typography>
        {extraLabel && <Typography fontSize={14} style={{ marginLeft: "10px", opacity: 0.4 }}>{` ${extraLabel}`}</Typography>}
      </div>
      <TextField
        error={error}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        width={"20%"}
        data-testid={datatestid}
        type={type}
        autoComplete="new-password"
        InputProps={InputProps}
        size={size ? size : "small"}
        helperText={helperText}
      />
    </div>

  )
}

export default InputField