import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: "flex",
    flexDirection:(props) => (props.reverseRow ? "row-reverse" : null),
    flexFlow: (props) => (props.wrapActions ? "wrap" : null),
    justifyContent: (props) => (props.usedin === "LogsFilter" ? "flex-end" : ""),
    "& *": {
      backgroundColor: "initial !important",
    },
  },
  action: {
    display: "flex",
    marginLeft: "5px",
    marginRight: "5px",
    "&[disabled]": {
      cursor: "default",
      opacity: "0.4",
      "&:hover": {
        textDecoration: "none",
      },
      color: "#a3d0f",
    },
  },
  icon: {},
  actionTitle: {
    color: "inherit",
    fontWeight: 600,
  },
  center: {
    alignSelf: "center",
    "&:hover": {
      background: "#E8F3FF",
    },
  },
}));

export default function Actions(props) {
  const classes = useStyles(props);
  const { actions, actionParams } = props;
  
  return (
    <div className={classes.actionContainer}>
      {actions?.map((action, index) => {
          if (
            (typeof action.hide === "function" && action.hide(actionParams)) ||
            (typeof action.hide !== "function" && action.hide)
          ) {
            return null;
          }

          return action?.component ? (
            <React.Fragment data-testid={action?.["data-testid"]} key={"action-" + index}>
              {typeof action.component === "function"
                ? action.component(actionParams)
                : action.component}
            </React.Fragment>
          ) : (
            <div key={"action-" + index} className={classes.center}>
              <Link
                data-testid={action?.["data-testid"]}
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.preventDefault();
                  action.handler({ ...(actionParams ? actionParams : {}) });
                }}
                disabled={
                  action.disabled &&
                  typeof action.disabled === "function" &&
                  action.disabled(actionParams)
                }
                className={classes.action}
              >
                {action.icon && (
                  <div className={classes.icon}>
                    {typeof action.icon === "function" ? action.icon(actionParams) : action.icon}
                  </div>
                )}
                <Typography variant="subtitle1" className={classes.actionTitle}>
                  {action.title}
                </Typography>
              </Link>
            </div>
          );
        })}
    </div>
  );
}
