import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import TableComponent from '../common/TableComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from '../common/ConfirmModal'

const UserList = (props) => {
    const { usersData, setUsersData } = props;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedUserToDelete, setSelectedUserToDelete] = useState();

    const columns = [
        {
            field: 'userName', headerName: 'User Name', width: 500, sortable: false,
            renderHeader: (params) => (
                <strong>
                    {'User Name '}
                </strong>),
        },
        {
            field: 'firstName', headerName: 'First Name', width: 300, sortable: false,
            renderHeader: (params) => (
                <strong>
                    {'First Name '}
                </strong>),
        },
        {
            field: 'lastName', headerName: 'Last Name', width: 300, sortable: false,
            renderHeader: (params) => (
                <strong>
                    {'Last Name '}
                </strong>),
        },
        {
            field: 'phoneNo', headerName: 'Phone Number', width: 300, sortable: false,
            renderHeader: (params) => (
                <strong>
                    {'Phone Number'}
                </strong>),
        },
        {
            field: 'actions', headerName: 'Actions', width: 300, sortable: false,
            renderHeader: (params) => (
                <strong>
                    {'Actions'}
                </strong>),
            renderCell: (params) => (
                <DeleteIcon color="secondary" style={{ cursor: "pointer" }} onClick={() => {
                    setSelectedUserToDelete(params.row);
                    setDeleteModalOpen(true);
                }} />
            )
        }
    ];



    const handleDelete = () => {
        const filteredData = usersData.filter((a) => {
            return a.id !== selectedUserToDelete?.id;
        })
        setUsersData(filteredData);
        setDeleteModalOpen(false);
        setSelectedUserToDelete(null);
    }

    return (
        <Grid>

            <ConfirmModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title={"Delete User"}
                verificationString={selectedUserToDelete?.userName}
                description={`Are you sure you want to delete ${selectedUserToDelete?.userName} ?`}
                placeholderLabel={`Type ${selectedUserToDelete?.userName} to confirm..`}
                onSubmitHandler={handleDelete}
            />

            <Box sx={{ width: '100%' }}>
                <TableComponent
                    columns={columns}
                    dataSet={usersData}
                    loadingData={false}
                />
            </Box >
        </Grid>
    )


}

export default UserList