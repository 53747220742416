import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HTTPClient from "../common/servicers/httpClient";
import { HTTP_METHODS, CALLBACK_REDIRECT_URI } from "../common/constants";
import { fetchSessionItem, setSessionItem } from "../storage";
import { useAuthContext } from "../common/contexts/AuthContext";
import { decodeToken } from "../common/utils";
import { setRole } from "../common/roleSlice";
import { useDispatch } from "react-redux";
import LoadingModal from "./common/LoadingModal";

const LoginCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { dispatch: dispatchAuth, state: authData } = useAuthContext();
    const getAccessCode = async (code) => {
        try {
            const response = await new HTTPClient({
                endpoint: "/authenticate",
                method: HTTP_METHODS.POST,
                data: {
                    authorizationCode: code,
                    redirectURI: CALLBACK_REDIRECT_URI
                },
            }).callUnauthorizedAPI();

            if (response?.status === 200) {

                const decodeAccessToken = decodeToken(response?.data?.access_token);

                for (const key in decodeAccessToken) {
                    let value = key.split("/");
                    if (value[value.length - 1] === "role") {
                        setSessionItem("role", decodeAccessToken[key]);
                        dispatch(setRole(decodeAccessToken[key]));
                    }
                }
                setSessionItem("email", decodeAccessToken?.email);
                setSessionItem("name", decodeAccessToken?.email);
                setSessionItem("access_token", response?.data?.access_token);
                setSessionItem("expires_on", response?.data?.expires_on);
                setSessionItem("token_type", response?.data?.token_type);
                setSessionItem("selectedCustomer", decodeAccessToken?.customerCode);
                dispatchAuth({ type: "authenticate" });

                if (fetchSessionItem("role") === "Tenant Admins") {
                    navigate("/home");
                } else {
                    const selectedCustomer = sessionStorage.getItem("selectedCustomer");
                    navigate(`/users/${selectedCustomer}`);
                }
            }
        }
        catch (error) {
            if (error.response.status !== 200) {
                dispatchAuth({ type: "unAuthenticate" });
                sessionStorage.clear();
                navigate("/LoginError");
            }
        }
    }


    useEffect(() => {
        const code = (location.search.match(/code=([^&]+)/) || [])[1];
        // Calling Unauthorized API
        getAccessCode(code);
    });

    return <>
        <LoadingModal
            open={true}
            loaderText={"Please wait.."} />
    </>
};

export default LoginCallback;