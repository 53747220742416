import { Button, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

const LoginWrapper = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Typography variant="h5" color="#3296ff" align='center' style={{marginTop:"20px"}}>
        Seems like you are signed out. 
        <Typography>Click to Sign in</Typography>
        <Button onClick={() =>{
          sessionStorage.clear();
          navigate("/");
        }} variant="outlined" color="success" style={{marginTop:"10px"}}>Sign in</Button>
      </Typography>
      
    </Fragment>
  )
}

export default LoginWrapper