import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import CustomerDetails from './CustomerDetails'
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import { useSelector } from 'react-redux';

const EditCustomer = () => {
const { editingCustomer, editCustomerSuccess, editCustomerError } = useSelector(
        (state) => state.customers
);

  return (
    <Grid>
      <Snackbar
        open={editCustomerError}
        severity="error"
        message={
          editCustomerError?.detail
            ? editCustomerError?.detail
            : editCustomerError?.message
              ? editCustomerError?.message
              : "Unable to edit"
        }
      />
      
      <LoadingModal
        loaderText={"Please wait.."}
        open={editingCustomer}
      />

      {/* <CustomerDetails
        customerName={customerName}
        customerCode={customerCode}
        deploymentCode={deploymentCode}
        windowsSharePath={windowsSharePath}
        setCustomerName={setCustomerName}
        setCustomerCode={setCustomerCode}
        setDeploymentCode={setDeploymentCode}
        setWindowsSharePath={setWindowsSharePath}
      /> */}

      <Grid item style={{ display: "flex", flexDirection: "row-reverse", marginRight: "10px" }}>
        <Button variant="contained" onClick={()=>{console.log("Edit")}}>Edit Customer</Button>
      </Grid>


    </Grid>
  )
}

export default EditCustomer