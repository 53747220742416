import React from 'react'
import { Grid } from '@mui/material';
import TabsComponent from '../common/TabsComponent';

const AddUsersTabWrapper = (props) => {
    const tabData = [
        {
          label: "Add Users",
          value: "Add Users",
          handler : () =>{
            props.onTabChange("Add Users");
          }
        },
        {
          label: "Import Users",
          value: "Import Users",
          handler : () =>{
            props.onTabChange("Import Users");
          }
        },
      ];

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
        <TabsComponent 
        setTab={props?.tabValue}
        tabData ={tabData}
        defaultValue={"Add Users"}
        />
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
        {
            props.tabValue === "Add Users" ? props.componentA : props.componentB
        }
        </Grid>

      </Grid>
  )
}

export default AddUsersTabWrapper