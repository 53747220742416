
import React, { useEffect, useState } from 'react'
import { Grid, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { updateUser, reset } from "./usersSlice";
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import InputField from '../common/InputField'
import { SNACKBAR_DURATION_MS } from "../../common/constants";

const EditUser = () => {

    const { customerCode, userName } = useParams();
    const { users, updatingUser, updateSuccess, updateError } = useSelector((state) => state.users);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [selectedUser, setSelectedUsers] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        users?.map((a) => {
            if (a?.userPrincipalName === userName) {
                setSelectedUsers(a);
                setFirstName(a?.firstName);
                setLastName(a?.lastName);
                setDisplayName(a?.displayName);
                setPhoneNo(a?.phoneNo);
            }
        })
    }, [userName, users]);

    const handleUpdate = () => {
        const payload = [{
            userPrincipalName: userName,
            firstName: firstName,
            lastName: lastName,
            telephoneNumber: phoneNo ? phoneNo.toString() : "",
            displayName: displayName,
        }]

        console.log(payload);
        dispatch(updateUser(payload));
    }
    React.useEffect(() => {
        if (updateSuccess === true) {
            navigate(`/users/${customerCode}`);
        }
        setTimeout(() => {
            dispatch(reset());
        }, SNACKBAR_DURATION_MS);
    }, [updateSuccess])
    return (
        <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>
            <LoadingModal
                open={updatingUser}
                loaderText={"Please wait.."} />
            <Snackbar
                open={updateError}
                severity="error"
                message={
                    updateError?.detail
                        ? updateError?.detail
                        : updateError?.message
                            ? updateError?.message
                            : "Unable to Update User"
                }
            />
            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"username-input-field"}
                    label={"User Name *"}
                    value={userName}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"displayName-input-field"}
                    label={"Display Name *"}
                    value={displayName}
                    onChange={(e) => {
                        setDisplayName(e.target.value);
                    }}
                />
            </Grid>
            {/* <Grid item xs={7} md={7} lg={7} xl={7}></Grid> */}

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"firstname-input-field"}
                    label={"First Name"}
                    value={firstName}
                    type={"text"}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                />
            </Grid>
            {/* <Grid item xs={7} md={7} lg={7} xl={7}></Grid> */}

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"lastName-input-field"}
                    label={"Last Name"}
                    value={lastName}
                    type={"text"}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    extraLabel={"(Optional)"}
                    datatestid={"phoneNo-input-field"}
                    label={"Phone No."}
                    value={phoneNo}
                    type="Number"
                    onChange={(e) => {
                        setPhoneNo(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={7} md={7} lg={7} xl={7}>
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5} style={{ display: "flex", flexDirection: "row-reverse" }}>

                <Button
                    datatestid={"edit-user-button"}
                    onClick={() => {
                        handleUpdate();
                    }} style={{ backgroundColor: "#1a8aff", color: "white" }}>Update User</Button>

            </Grid>

        </Grid>
    )
}

export default EditUser