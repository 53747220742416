import React from 'react'
import { useState, useEffect } from 'react';
import TableComponent from "../common/TableComponent";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Divider, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import TabsComponent from '../common/TabsComponent';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import { applyPermissions, reset } from './foldersSlice';
import { SNACKBAR_DURATION_MS } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import { getGroups } from '../Groups/groupsSlice';
import { getUsers } from '../Users/usersSlice';
import { getFolders } from './foldersSlice';

const USERS_TAG = 'user';
const GROUPS_TAG = 'group';

const users_column = [
    { field: 'userPrincipalName', headerName: 'User Name', width: 500, sortable: false },
    { field: 'samAccountName', headerName: 'SAM Account Name', width: 300, sortable: false },
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Hello",
        width: 100,
    },

];

const groups_column = [
    { field: 'name', headerName: 'Group Name', width: 300, sortable: false },
    { field: 'samAccountName', headerName: 'SAM Account Name', width: 300, sortable: false },
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Hello",
        width: 100,
    }
];



const FolderPermission = () => {
    const customerCode = sessionStorage.getItem('selectedCustomer');
    const { name } = useParams();
    const [usersData, setUsersData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [permissionsData, setPermissionsData] = useState([]);
    const [deletedPermissionsData, setDeletedPermissionsData] = useState([]);
    const { folders, errorPermissionsAndNextFolder, loadingFolderPermissions, applyPermissionsLoading, applyPermissionsError, applyPermissionsSuccess } = useSelector((state => state.folders));
    const { users } = useSelector((state => state.users));
    const { groups } = useSelector((state => state.groups));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const permissions_column = [
        {
            field: 'account', headerName: 'User/Group Name', width: 300, sortable: false,
        },
        {
            field: 'fullaccess',
            headerName: 'Full Access',
            width: 300,
            renderCell: (params) => (
                <>
                    <Checkbox checked={params.row.rights === 'FullControl'} onChange={(e) => {
                        if (e.target.checked === true) {
                            setPermissionsData(permissionsData.map((a) => {
                                if (a?.id === params?.row?.id) {
                                    if (a.changed && a.changed === true) {
                                        a.changed = false;
                                    } else {
                                        return { ...a, changed: true, rights: 'FullControl' };
                                    }
                                    return { ...a, rights: 'FullControl' };
                                } else {
                                    return { ...a };
                                }
                            }))
                        } else {
                            setPermissionsData(permissionsData.map((a) => {
                                if (a?.id === params?.row?.id) {
                                    if (a.changed && a.changed === true) {
                                        a.changed = false;
                                    } else {
                                        return { ...a, changed: true, rights: 'Read' };
                                    }
                                    return { ...a, rights: 'Read' };
                                } else {
                                    return { ...a };
                                }
                            }))
                        }
                    }} />
                </>
            )
        },
        {
            field: 'read',
            headerName: 'Read Access',
            width: 300,
            renderCell: (params) => (
                <>
                    <Checkbox checked={params.row.rights !== 'FullControl'} onChange={(e) => {
                        if (e.target.checked === true) {
                            setPermissionsData(permissionsData?.map((a) => {
                                if (a?.id === params?.row?.id) {
                                    if (a.changed && a.changed === true) {
                                        a.changed = false;
                                    } else {
                                        return { ...a, changed: true, rights: 'Read' };
                                    }
                                    return { ...a, rights: 'Read' };
                                } else {
                                    return { ...a };
                                }
                            }))
                        } else {
                            setPermissionsData(permissionsData.map((a) => {
                                if (a?.id === params?.row?.id) {
                                    if (a.changed && a.changed === true) {
                                        a.changed = false;
                                    } else {
                                        return { ...a, changed: true, rights: 'FullControl' };
                                    }
                                    return { ...a, rights: 'FullControl' };
                                } else {
                                    return { ...a };
                                }
                            }))
                        }
                    }} />
                </>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => (
                <>
                    <DeleteIcon color="secondary" style={{ cursor: "pointer" }} onClick={() => {

                        if (params?.row?.tag === GROUPS_TAG) {
                            setGroupsData(groupsData.map((a) => {
                                if (a.groupName === params.row.name) {
                                    return { ...a, enabled: true };
                                } else {
                                    return { ...a };
                                }
                            }))
                        } else if (params?.row?.tag === USERS_TAG) {
                            setUsersData(usersData.map((a) => {
                                if (a.userPrincipalName === params.row.account) {
                                    return { ...a, enabled: true };
                                } else {
                                    return { ...a };
                                }
                            }))
                        }
                        let data = [];
                        setPermissionsData(permissionsData.filter((a) => {
                            if (a.id === params.row.id && !a.new) {
                                data.push(a);
                            }
                            return a.id !== params.row.id;
                        }));
                        setDeletedPermissionsData(data);
                    }} />
                </>
            )
        }
    ];

    // retrieve users, groups and folders for the customer if not already populated
    useEffect(() => {
        if (users?.length === 0) {
          dispatch(getUsers(customerCode));
        }
        if (groups?.length === 0) {
          dispatch(getGroups(customerCode));
        }
        if (folders?.length === 0) {
            dispatch(getFolders(customerCode));
        }        
      }, [customerCode])
      
    // populate the users, groups and permissions data (if present)
    useEffect(() => {
        if (users?.length > 0 && groups?.length > 0) {
            const currentPermissionSamAccount = [];
            permissionsData?.forEach((a) => {
                currentPermissionSamAccount.push(a?.account);
            });

            const currentPermissionSamAccountSet = new Set(currentPermissionSamAccount);
            setUsersData(users.map((a) => {
                if (currentPermissionSamAccountSet.has(a?.samAccountName)) {
                    return { ...a, enabled: false }
                } else {
                    return { ...a, enabled: true }
                }
            }))
            setGroupsData(groups.map((a) => {
                if (currentPermissionSamAccountSet.has(a?.samAccountName)) {
                    return { ...a, enabled: false }
                } else {
                    return { ...a, enabled: true }
                }
            }))

        }
    }, [permissionsData])


    useEffect(() => {
        if (applyPermissionsSuccess === true) {
            navigate(`/folders/${customerCode}`);
        }
        setTimeout(() => {
            dispatch(reset());
        }, SNACKBAR_DURATION_MS);
    }, [applyPermissionsSuccess, applyPermissionsError])


    useEffect(() => {
        if (loadingFolderPermissions === false) {
            var customerFolders = folders[customerCode];
            for (var folder in customerFolders) {
                let x = getPermissions(customerFolders[folder]);
                if (x === 1) break;
            }

        }
    }, [loadingFolderPermissions])
    const [tabValue, setTabValue] = useState("USER");

    const getPermissions = (folder) => {
        if (folder.id === name) {
            setPermissionsData(folder?.permissions || []);
            return 1;
        } else if (folder.children && folder.children.length > 0) {
            folder.children.map(
                (childFolder) => getPermissions(childFolder))
        }
        return 0;
    }

    const handleApplyPermissions = () => {
        const payload = {
            dataVolume: name,
            addFullAccess: permissionsData.filter((permissionData) => { return (permissionData.new === true || permissionData.changed === true) && permissionData.rights === "FullControl" }).map((permissionData) => { return permissionData.account }),
            addReadAccess: permissionsData.filter((permissionData) => { return (permissionData.new === true || permissionData.changed === true) && permissionData.rights !== "FullControl" }).map((permissionData) => { return permissionData.account }),
            removeFullAccess: deletedPermissionsData.filter((permissionData) => { return permissionData.rights === "FullControl" }).map((permissionData) => { return permissionData.account }),
            removeReadAccess: deletedPermissionsData.filter((permissionData) => { return permissionData.rights !== "FullControl" }).map((permissionData) => { return permissionData.account }),
        }
        dispatch(applyPermissions(payload));
    }

    const tabData = [
        {
            label: "Users",
            value: "USER",
            handler: () => {
                setTabValue("USER")
            }
        },
        {
            label: "Groups",
            value: "GROUP",
            handler: () => {
                setTabValue("GROUP")
            }
        },
    ];


    return (


        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
            <Snackbar
                open={errorPermissionsAndNextFolder}
                severity="error"
                message={
                    errorPermissionsAndNextFolder?.detail
                        ? errorPermissionsAndNextFolder?.detail
                        : errorPermissionsAndNextFolder?.message
                            ? errorPermissionsAndNextFolder?.message
                            : "Unable to fetch permissions"
                }
            />
            <Snackbar
                open={applyPermissionsError}
                severity="error"
                message={
                    applyPermissionsError?.detail
                        ? applyPermissionsError?.detail
                        : applyPermissionsError?.message
                            ? applyPermissionsError?.message
                            : "Unable to apply permissions"
                }
            />
            <LoadingModal
                open={loadingFolderPermissions || applyPermissionsLoading}
                loaderText={"Please wait.."} />
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="p" fontWeight={700} color="black" sx={{ mr: 1 }}>
                    Permissions Management : </Typography>
                <Typography variant="p" fontWeight={700} color="#1a8aff">{name}
                </Typography>
            </div>


            <TableComponent
                columns={permissions_column}
                dataSet={permissionsData}
                loadingData={false}
                tableActions={[
                    {
                        title: (
                            <span style={{ display: "flex", marginRight: "5px" }}>
                                {"Apply Permissions"}
                            </span>
                        ),
                        handler: () => {
                            handleApplyPermissions();
                        },
                        "data-testid": "folders-add-permissions",
                    }
                ]}
            />

            <Typography variant="p" fontWeight={700}>Add Users/Groups to Folder</Typography>

            <TabsComponent
                setTab={tabValue}
                tabData={tabData}
                defaultValue={tabValue}
            />

            {tabValue === "USER" && <TableComponent
                columns={users_column}
                dataSet={usersData}
                onRowSelectionModelChange={(ids) => {
                    setSelectedUsers(ids);
                }}
                rowSelectionModel={selectedUsers}
                isRowSelectable={(params) => {
                    return params.row.enabled;
                }}
                loadingData={false}
                checkboxSelection
                tableActions={[
                    {
                        title: (
                            <span style={{ display: "flex", marginRight: "5px" }}>
                                {"Add Users"}
                            </span>
                        ),
                        handler: () => {
                            const selectedIds = new Set(selectedUsers);
                            const modifiedPermissionsData = [...permissionsData];
                            setUsersData(usersData.map((a) => {
                                if (selectedIds.has(a.id)) {
                                    modifiedPermissionsData.push({ account: a.samAccountName, id: `${a.id}u`, tag: a.tag, fullaccess: false, noaccess: true, new: true });
                                    return { ...a, enabled: false }
                                } else {
                                    return { ...a };
                                }
                            }));
                            setPermissionsData(modifiedPermissionsData);
                            setSelectedUsers([]);
                        },
                        "data-testid": "add-users",
                    }
                ]}
            />}

            {tabValue === "GROUP" && <TableComponent
                columns={groups_column}
                dataSet={groupsData}
                onRowSelectionModelChange={(ids) => {
                    setSelectedGroups(ids);
                }}
                rowSelectionModel={selectedGroups}
                isRowSelectable={(params) => {
                    return params.row.enabled;
                }}
                loadingData={false}
                checkboxSelection
                tableActions={[
                    {
                        title: (
                            <span style={{ display: "flex", marginRight: "5px" }}>
                                {"Add Groups"}
                            </span>
                        ),
                        handler: () => {
                            const selectedIds = new Set(selectedGroups);
                            const modifiedPermissionsData = [...permissionsData];
                            setGroupsData(groupsData.map((a) => {
                                if (selectedIds.has(a.id)) {
                                    modifiedPermissionsData.push({ id: `${a.id}g`, account: a.samAccountName, tag: a.tag, fullaccess: false, noaccess: true, new: true });
                                    return { ...a, enabled: false }
                                } else {
                                    return { ...a };
                                }
                            }));
                            setPermissionsData(modifiedPermissionsData);
                            setSelectedGroups([]);
                        },
                        "data-testid": "add-groups",
                    }
                ]}
            />}
        </div>
    )
}

export default FolderPermission



