import {createSlice } from "@reduxjs/toolkit";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const initialState = {
  role: null,
};

export const role = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    }
  },
});

export const { setRole } = role.actions;

export default role.reducer;
