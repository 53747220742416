import React from 'react'
import { useEffect } from 'react';
import TreeViewComponent from '../common/TreeViewComponent'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import { getFolders } from './foldersSlice';
import { getGroups } from '../Groups/groupsSlice';
import { getUsers } from '../Users/usersSlice';

const Folders = () => {
  const { customerCode } = useParams();
  const dispatch = useDispatch();
  const { users } = useSelector((state => state.users));
  const { groups } = useSelector((state => state.groups));
  useEffect(() => {
    dispatch(getFolders(customerCode));
    if (users?.length === 0) {
      dispatch(getUsers(customerCode));
    }
    
    dispatch(getGroups(customerCode));
    
  }, [customerCode])

  return (
    <div>

      {/* <TableComponent 
       columns={columns}
       dataSet = {folders?.[`${customerCode}`]?.map((a,index) =>{
        return {...a, id:index};
       }) || []}
       loadingData ={loadingFolders}
      /> */}


      <TreeViewComponent />

    </div>
  )
}

export default Folders