import * as React from 'react';
import { useState, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissionsAndNextFolder } from '../Folders/foldersSlice';
import LoadingModal from './LoadingModal';
import Snackbar from '../common/Snackbar';

export default function TreeViewComponent() {

  const { loadingFolders, folders, getFoldersError, loadingFolderPermissions, errorPermissionsAndNextFolder } = useSelector((state => state.folders));
  const { loadingUsers } = useSelector((state => state.users));
  const { loadingGroups } = useSelector((state => state.groups));

  const [selectedFolder, setSelectedFolder] = useState("");
  const navigate = useNavigate();
  const selectedCustomer = sessionStorage.getItem("selectedCustomer");
  const dispatch = useDispatch();


  useEffect(() => {
    if (loadingFolderPermissions === false && selectedFolder !== "") {
      let customerCode = sessionStorage.getItem("selectedCustomer");
      let x = encodeURIComponent(selectedFolder);
      setSelectedFolder("");
      navigate(`/folders/${customerCode}/permissions/${x}`)
    }
  }, [loadingFolderPermissions])

  const handleClick = (event, name) => {
    event.stopPropagation();
    setSelectedFolder(name);
    dispatch(getPermissionsAndNextFolder({ sharedFolderPath: name, useCache: false }));
  };



  const renderTree = (nodes) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TreeItem data-testid={`tree_item_${nodes.name}`} key={nodes.id} nodeId={nodes.id} label={<div onClick={event => handleClick(event, nodes.id)}>{nodes?.description || nodes.name}</div>} onClick={() => {
        if (!nodes?.expandedForChild) {
          dispatch(getPermissionsAndNextFolder({ sharedFolderPath: nodes.path, useCache: false }));
        } else {
          dispatch(getPermissionsAndNextFolder({ sharedFolderPath: nodes.path, useCache: true }));
        }
      }
      }>

        {
          Array.isArray(nodes.children)
            ? nodes.children.map(
              (node) => renderTree(node))
            : null}
      </TreeItem>
    </div >
  );
  return (
    <>
      <LoadingModal
        open={loadingFolderPermissions || loadingFolders || loadingUsers || loadingGroups}
        loaderText={"Please wait.."} />
      <Snackbar
        open={errorPermissionsAndNextFolder}
        severity="error"
        message={
          errorPermissionsAndNextFolder?.detail
            ? errorPermissionsAndNextFolder?.detail
            : errorPermissionsAndNextFolder?.message
              ? errorPermissionsAndNextFolder?.message
              : "Unable to fetch permissions"
        }
      />
      <Snackbar
        open={getFoldersError}
        severity="error"
        message={
          getFoldersError?.detail
            ? getFoldersError?.detail
            : getFoldersError?.message
              ? getFoldersError?.message
              : "Unable to fetch folder"
        }
      />
      <div style={{ margin: "0px 12px 12px 12px" }}>

        <div style={{ marginTop: "10px" }}>
          <TreeView
            data-testid="tree-view"
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            // defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
          >
            {folders[`${selectedCustomer}`]?.map((a) => (
              renderTree(a)
            ))}
          </TreeView>
        </div>
      </div>
    </>
  );
}