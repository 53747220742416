import { Button, Grid, InputAdornment, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import InputField from '../common/InputField'
import { composeValidators, required, checkMobile } from '../common/validators';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AddUsers = (props) => {
    const dispatch = useDispatch();
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [phoneNo, setPhoneNo] = useState();
    const [password, setPassword] = useState();
    const [visible, setVisibility] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [showUserNameError, setShowUserNameError] = useState(false);

    const { usersData, setUsersData, customerCode } = props;

    const resetState = () => {
        setUserName("");
        setFirstName("");
        setLastName("");
        setDisplayName("");
        setPhoneNo("");
        setPassword("");
    }

    useEffect(() => {
        if (isValid()) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }
    }, [firstName, lastName, userName, displayName])


    const isValid = () => {
        return !!firstName && !!lastName && !!userName && !!displayName && !showUserNameError
    }

    const handleClick = () => {
        if (!userName || !firstName || !lastName || !displayName || showUserNameError) return;
        const row = { userName, firstName, lastName, displayName, phoneNo, password };
        row.userName = userName + `${process.env.REACT_APP_UPN_SUFFIX}`;
        const new_data = [row, ...usersData]
        setUsersData(new_data.map((a, index) => {
            return { ...a, id: index }
        }));
        resetState();
    }

    const isUserNameValid = (userName) => {
        if (userName.indexOf('@') > -1 || userName.indexOf(' ') > -1) {
            setShowUserNameError(true);
        }
        else {
            setShowUserNameError(false);
        }
    }
    return (
        <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"firstName-input-field"}
                    label={"First Name *"}
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        setDisplayName(`${e.target.value} ${lastName}`)
                        setUserName(`${e.target.value?.charAt(0)}.${lastName}`)
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"lastName-input-field"}
                    label={"Last Name *"}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                        setDisplayName(`${firstName} ${e.target.value}`);
                        setUserName(`${firstName?.charAt(0)}.${e.target.value}`);
                        isUserNameValid(`${e.target.value}`);
                    }}
                />
            </Grid>


            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"username-input-field"}
                    label={"User Name *"}
                    value={userName}
                    onChange={(e) => {
                        setUserName(`${e.target.value}`);
                        isUserNameValid(`${e.target.value}`);

                    }}
                    error={showUserNameError}
                    helperText={showUserNameError ? "Username field should not contain @ and space" : ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>{customerCode ? "." + customerCode : ".[customercode]"}{process.env.REACT_APP_UPN_SUFFIX}</Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    datatestid={"displayName-input-field"}
                    label={"Display Name *"}
                    value={displayName}
                    onChange={(e) => {
                        setDisplayName(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    extraLabel={"(Optional)"}
                    datatestid={"password-input-field"}
                    label={"Password"}
                    value={password}
                    type={visible ? "text" : "password"}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {visible ?
                                    <VisibilityIcon style={{ cursor: "pointer", color: "#0063a3" }} onClick={
                                        () => {
                                            setVisibility(!visible);
                                        }
                                    } /> :
                                    < VisibilityOffIcon style={{ cursor: "pointer", color: "#0063a3" }} onClick={
                                        () => {
                                            setVisibility(!visible);
                                        }
                                    } />
                                }
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    extraLabel={"(Optional)"}
                    datatestid={"phoneNo-input-field"}
                    label={"Phone No."}
                    value={phoneNo}
                    type="Number"
                    onChange={(e) => {
                        setPhoneNo(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}>

            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={5} md={5} lg={5} xl={5} style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                    disabled={enableSubmit ? false : true}
                    datatestid={"add-user-button"}
                    onClick={handleClick} style={{
                        backgroundColor: enableSubmit ? "#1a8aff" : "#e0e0e0",
                        color: "white"
                    }}>Add</Button>
            </Grid>

        </Grid >
    )
}

export default AddUsers