import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import * as XLSX from "xlsx";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getUsers = createAsyncThunk(
    "getUsers",
    async (rejectWithValue) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${selectedCustomer}`,
                method: HTTP_METHODS.GET,
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getUserDetails = createAsyncThunk(
    "getUserDetails",
    async (userName, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${selectedCustomer}/${userName}`,
                method: HTTP_METHODS.GET,
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const handleExport = (data) => {
    var wb = XLSX.utils.book_new();
    let convert_data = []
    data?.message?.successUserList?.map((a) => {
        convert_data.push({
            Response: a
        })
    })
    data?.message?.failedUserList?.map((a) => {
        convert_data.push({
            Response: a
        })
    })
    var ws = XLSX.utils.json_to_sheet(convert_data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet_1");
    XLSX.writeFile(wb, "UsersData.xlsx");
}

export const createUsers = createAsyncThunk(
    "createUsers",
    async (payload, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${selectedCustomer}`,
                method: HTTP_METHODS.POST,
                data: payload,
            }).callAuthorizedAPI();
            console.log("Data", response.data);
            handleExport(response.data);
            return response.data;
        } catch (error) {
            handleExport(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteUser = createAsyncThunk(
    "deleteUser",
    async (userName, { rejectWithValue }) => {
        const customerCode = sessionStorage.getItem("selectedCustomer");
        console.log(customerCode, userName);
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${customerCode}/${userName}`,
                method: HTTP_METHODS.DELETE
            }).callAuthorizedAPI();
            return { ...response.data, userName };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updatePassword = createAsyncThunk(
    "updatePassowrd",
    async ({ userName, payload }, { rejectWithValue }) => {
        const customerCode = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${customerCode}/${userName}/password`,
                method: HTTP_METHODS.PUT,
                data: payload
            }).callAuthorizedAPI();
            console.log(response.data);
            return { ...response.data };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateUser = createAsyncThunk(
    "updateUser",
    async (payload, { rejectWithValue }) => {
        const customerCode = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/users/${customerCode}`,
                method: HTTP_METHODS.PUT,
                data: payload
            }).callAuthorizedAPI();
            console.log(response.data);
            return { ...response.data };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const initialState = {
    users: [],
    loadingUsers: null,
    getUsersError: null,
    createSuccess: null,
    createError: null,

    userDetails: {},
    loadingUserDetails: null,
    userDetailsError: null,

    deletingUser: null,
    deleteSuccess: null,
    deleteError: null,

    updatingUser: null,
    updateSuccess: null,
    updateError: null,

    updatingPassword: null,
    updatePasswordSuccess: null,
    updatingPasswordError: null
};

export const usersSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.loadingUsers = null;
            state.getUsersError = null;
            state.createSuccess = null;
            state.createError = null;
            state.userDetailsError = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.updatingPasswordError = null;
            state.updatePasswordSuccess = null;
            state.updatingUser = null;
            state.updateSuccess = null;
            state.updateError = null;
        },

    },
    extraReducers: {
        [getUsers.pending]: (state, action) => {
            state.loadingUsers = true;
            state.currentRequestId = action.meta.requestId;
        },
        [getUsers.fulfilled]: (state, action) => {

            state.currentRequestId = null;
            let data = []
            let count = 0;
            if (action.payload) {
                count = 0;
                action.payload.map((a) => {
                    data.push({ ...a, id: count });
                    count++;
                })
            }
            state.users = action.payload ? data : [];
            state.loadingUsers = false;
        },
        [getUsers.rejected]: (state, action) => {
            state.getUsersError = action.payload;
            state.loadingUsers = false;
            state.currentRequestId = null;
        },
        [getUserDetails.pending]: (state, action) => {
            state.loadingUserDetails = true;
            state.currentRequestId = action.meta.requestId;
        },
        [getUserDetails.fulfilled]: (state, action) => {
            state.userDetails = action.payload ? action.payload : {};
            state.loadingUserDetails = false;
        },
        [getUserDetails.rejected]: (state, action) => {
            state.userDetailsError = action.payload;
            state.loadingUserDetails = false;
            state.currentRequestId = null;
        },
        [createUsers.pending]: (state, action) => {
            state.loadingUsers = true;
            state.currentRequestId = action.meta.requestId;
        },
        [createUsers.fulfilled]: (state, action) => {
            state.loadingUsers = false;
            state.currentRequestId = action.meta.requestId;
            state.createSuccess = true;
            // state.createdUsers = action.payload
        },
        [createUsers.rejected]: (state, action) => {
            state.loadingUsers = false;
            state.createError = action.payload;
            state.createSuccess = false;
        },
        [updatePassword.pending]: (state, action) => {
            state.loadingUpdate = true;
            state.currentRequestId = action.meta.requestId;
        },
        [updatePassword.fulfilled]: (state, action) => {
            state.loadingUpdate = false;
            state.currentRequestId = action.meta.requestId;
            state.updatePasswordStatus = true;
            // state.createdUsers = action.payload
        },
        [updatePassword.rejected]: (state, action) => {
            state.loadingUpdate = false;
            state.updateError = action.payload;
            state.updatePasswordStatus = false;
        },
        [updateUser.pending]: (state, action) => {
            state.updatingUser = true;
            state.currentRequestId = action.meta.requestId;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.updatingUser = false;
            state.currentRequestId = action.meta.requestId;
            state.updateSuccess = true;
        },
        [updateUser.rejected]: (state, action) => {
            state.updatingUser = false;
            state.updateError = action.payload;
            state.updateSuccess = false;
        },
        [deleteUser.pending]: (state, action) => {
            state.deletingUser = true;
            state.currentRequestId = action.meta.requestId;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.deletingUser = false;
            state.currentRequestId = action.meta.requestId;
            state.deleteSuccess = true;
        },
        [deleteUser.rejected]: (state, action) => {
            state.deletingUser = false;
            state.deleteError = action.payload;
        },

        [updatePassword.pending]: (state, action) => {
            state.updatingPassword = true;
            state.currentRequestId = action.meta.requestId;
        },
        [updatePassword.fulfilled]: (state, action) => {
            state.updatingPassword = false;
            state.currentRequestId = action.meta.requestId;
            state.updatePasswordSuccess = true;
        },
        [updatePassword.rejected]: (state, action) => {
            state.updatingPassword = false;
            state.updatingPasswordError = action.payload;
        },

    },
});

export const { reset } = usersSlice.actions;

export default usersSlice.reducer;
