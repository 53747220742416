
import React, { useState } from 'react'
import AddUsers from '../Users/AddUsers'
import ImportUsers from '../Users/ImportUsers'
import AddUsersTabWrapper from '../Users/AddUsersTabWrapper'
import UserList from './UserList'
import { Grid, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { createUsers, reset } from "./usersSlice";
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import { SNACKBAR_DURATION_MS } from "../../common/constants";

const CreateUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [tabValue, setTabValue] = useState("Add Users");
  const selectedCustomer = sessionStorage.getItem("selectedCustomer");
  const { loadingUsers, users, createError, createSuccess } = useSelector(
    (state) => state.users
  );
  const handleSubmitUsers = () => {
    const payload =
      usersData.map((a) => {
        return {
          userPrincipalName: a.userName,
          firstName: a.firstName,
          lastName: a.lastName,
          telephoneNumber: a.phoneNo ? a.phoneNo.toString() : "",
          displayName: a.displayName,
          password: a.password,
          // samAccountName: a.userName,

        }
      })

    console.log(payload);
    dispatch(createUsers(payload));
  }

  React.useEffect(() => {
    if (createSuccess === true) {

      navigate(`/users/${selectedCustomer}`);
    }
    setTimeout(() => {
      dispatch(reset());
    }, SNACKBAR_DURATION_MS);
  }, [createSuccess])

  return (
    <Grid>
      <Snackbar
        open={createError}
        severity="error"
        message={
          "Unable to Create Users. Please check the downloaded spreadsheet"
        }
      />
      <LoadingModal
        loaderText={"Please wait.."}
        open={loadingUsers}
      />
      <AddUsersTabWrapper
        componentA={<AddUsers usersData={usersData} setUsersData={setUsersData} customerCode={selectedCustomer} />}
        componentB={<ImportUsers usersData={usersData} setUsersData={setUsersData} setTabValue={setTabValue} />}
        tabValue={tabValue}
        onTabChange={setTabValue} />
      <UserList usersData={usersData} setUsersData={setUsersData} />
      <Grid item style={{ display: "flex", flexDirection: "row-reverse", marginRight: "10px" }}>
        <Button data-testid={"submit-user"} variant="contained" onClick={handleSubmitUsers}>Submit Users</Button>
      </Grid>
    </Grid>
  )
}

export default CreateUsers