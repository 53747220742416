import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import InputField from '../common/InputField'
import SelectField from "../common/SelectField.jsx";
import { useSelector } from 'react-redux';

const CustomerDetails = (props) => {
    const { customerName, setCustomerName, customerCode, setCustomerCode, deploymentCode, setDeploymentCode, windowsSharePath, setWindowsSharePath } = props;
    const { loadingDeploymentCodes, deploymentCodes, errorDeploymentCode } = useSelector(
        (state) => state.customers
    );
    return (
        <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>
            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    label={"Customer Name *"}
                    value={customerName}
                    onChange={(e) => {
                        setCustomerName(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    label={"Customer Code"}
                    value={customerCode}
                    onChange={(e) => {
                        setCustomerCode(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}></Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <SelectField
                    label={"Deployment Code *"}
                    value={deploymentCode}
                    options={deploymentCodes || []}
                    onChange={(e) => {
                        setDeploymentCode(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={5} md={5} lg={5} xl={5}>
                <InputField
                    label={"Windows Share Path"}
                    value={windowsSharePath}
                    onChange={(e) => {
                        setWindowsSharePath(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}></Grid>




        </Grid>
    )
}

export default CustomerDetails