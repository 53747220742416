
import React, { useEffect, useState } from 'react'
import AddUsers from '../Users/AddUsers'
import ImportUsers from '../Users/ImportUsers'
import AddUsersTabWrapper from '../Users/AddUsersTabWrapper'
import UserList from './UserList'
import { Grid, Button, InputAdornment } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { createUsers, reset, updatePassword, loadingUpdate, updateError } from "./usersSlice";
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import InputField from '../common/InputField'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import LoadingButton from '@mui/lab/LoadingButton';
import { SNACKBAR_DURATION_MS } from "../../common/constants";

const EditUserSecret = () => {

    const { customerCode, userName } = useParams();
    const { users } = useSelector((state) => state.users);
    const [accountName, setAccountName] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [selectedUser, setSelectedUsers] = useState();
    const [isValid, setIsValid] = useState(false);
    const [visible, setVisibility] = useState(false);


    const { updatingPassword, updatePasswordSuccess, updatingPasswordError } = useSelector(
        (state) => state.users
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (updatePasswordSuccess) {
            navigate(`/users/${customerCode}`);
        }
    }, [updatePasswordSuccess])


    useEffect(() => {
        dispatch(reset());
    }, []);

    useEffect(() => {
        users?.map((a) => {
            if (a?.userPrincipalName === userName) {
                setSelectedUsers(a);
                setAccountName(a?.samAccountName);
            }
        })
    }, [userName, users]);

    useEffect(() => {
        if (!!newPassword && !!confirmPassword) {
            if (newPassword === confirmPassword) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            setIsValid(false);
        }
    }, [newPassword, confirmPassword]);


    return (
        <>

            <Snackbar
                open={updatingPasswordError}
                severity="error"
                message={
                    updatingPasswordError?.detail
                        ? updatingPasswordError?.detail
                        : updatingPasswordError?.message
                            ? updatingPasswordError?.message
                            : "Unable to Update"
                }
            />

            <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <InputField
                        datatestid={"userName-input-field"}
                        label={"User Name"}
                        value={userName}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7}></Grid>

                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <InputField
                        datatestid={"newPassword-input-field"}
                        label={"New Password"}
                        value={newPassword}
                        type={visible ? "text" : "password"}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {visible ?
                                        <VisibilityIcon style={{ cursor: "pointer", color: "#0063a3" }} onClick={
                                            () => {
                                                setVisibility(!visible);
                                            }
                                        } /> :
                                        < VisibilityOffIcon style={{ cursor: "pointer", color: "#0063a3" }} onClick={
                                            () => {
                                                setVisibility(!visible);
                                            }
                                        } />
                                    }
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
                <Grid item xs={7} md={7} lg={7} xl={7}></Grid>

                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <InputField
                        datatestid={"confirm-password-input-field"}
                        label={"Confirm Password"}
                        value={confirmPassword}
                        type={"password"}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }}
                        helperText={
                            (!!confirmPassword && !isValid) ? "Password is not matching!" : ""
                        }
                    />
                </Grid>

                <Grid item xs={7} md={7} lg={7} xl={7}>
                </Grid>

                <Grid item xs={5} md={5} lg={5} xl={5} style={{ display: "flex", flexDirection: "row-reverse" }}>

                    <LoadingButton
                        loading={updatingPassword}
                        disabled={isValid ? false : true}
                        variant='contained'
                        onClick={() => {
                            dispatch(updatePassword(
                                {
                                    userName: userName,
                                    payload: {
                                        samAccountName: accountName,
                                        newPassword
                                    }
                                }
                            ))
                        }}>Confirm</LoadingButton>


                </Grid >

            </Grid >
        </>
    )
}

export default EditUserSecret