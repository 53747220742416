import { Grid, Paper, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from './usersSlice';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Actions from '../common/Actions';
import TableComponent from '../common/TableComponent';


const UserDetails = () => {
    const dispatch = useDispatch();
    const {userName} = useParams();
    const { loadingUserDetails, userDetails, useDetailsError } = useSelector(
    (state) => state.users
    );
    
    const [details, setDetails] = useState({});
    const [memberOf, setMemberOf] = useState([]);
    useEffect(() => {
      if(!loadingUserDetails){
          setDetails({
            "First Name":userDetails?.firstName,
            "Last Name":userDetails?.lastName,
            "User Principal Name":userDetails?.userPrincipalName,
            "Account Name":userDetails?.samAccountName,
            "Display Name":userDetails?.displayName,
          })
          setMemberOf(userDetails?.memberOf?.map((a,index)=>{
            return {name:a, id:index}
          }));
      }
    }, [userDetails, loadingUserDetails])
    

    useEffect(() =>{
       dispatch(getUserDetails(userName));
    }, [userName]);

    const columns = [
      { field: 'name', headerName: 'Name', width: 300, sortable:false, 
        renderHeader: (params) => (
        <strong>
          {'Name '}
        </strong>),
      },   
    ];

  return (
  <>
     <Snackbar
        open={useDetailsError}
        severity="error"
        message={
          useDetailsError?.detail
            ? useDetailsError?.detail
            : useDetailsError?.message
              ? useDetailsError?.message
              : "Unable to Create"
        }
    />
    <div style={{display:"flex", flexDirection:"row-reverse", marginRight:"10px"}}>
        <Actions
        actions={[
            {  
             title:"Refresh",
            handler: () =>{
                dispatch(getUserDetails(userName));
            },
            "data-testid":"refreshButton"
        }]} />
    </div>
    <Paper>
    <Grid container sx={{m:2}}>    
        {
           details &&  Object.entries(details).map(element => (
                <Grid item xs={3} sm={3} md={3} xl={3} style={{padding:"5px"}}>
                <Typography fontWeight={700} color={"#0063a3"}>{element[0]}</Typography>
                { !loadingUserDetails ? <Typography fontSize={14}>{element[1]}</Typography> : <Skeleton width={100}/>}
                </Grid>
            ))
        }

    </Grid>
    <TableComponent
        columns={columns}
        dataSet={memberOf || []}
        loadingData={loadingUserDetails}
        tableHeading={"Member of"}
      />

    </Paper>
   </>
  )
}

export default UserDetails