import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SnackBar from "@material-ui/core/Snackbar";
import Alert from '@mui/material/Alert';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import { useTheme } from "@material-ui/core/styles";
import { SNACKBAR_DURATION_MS } from "../../common/constants";

const iconMapping = {
    success: <CheckCircleIcon fontSize="inherit" />,
    warning: <WarningIcon fontSize="inherit" />,
    info: <InfoIcon fontSize="inherit" />,
    error: <ErrorIcon fontSize="inherit" />,
};

const Snackbar = (props) => {
    const theme = useTheme();
    const { message, vertical, horizontal, variant, severity, disableAutoHide } = props;
    const [state, setState] = React.useState({
        open: props.open,
    });

    useEffect(() => {
        setState({ open: props.open || false });
    }, [props.open]);

    const handleClose = () => setState({ open: false });

    return (
        <div>
            <SnackBar
                data-testid={`${severity}-snackbar`}
                style={{ borderLeft: `2px solid ${theme.palette[severity].dark}` }}
                open={state.open}
                autoHideDuration={disableAutoHide ? null : SNACKBAR_DURATION_MS}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert
                variant={variant}
                onClose={handleClose}
                severity={severity}
                iconMapping={iconMapping}>
                    {message}
                </Alert>


            </SnackBar>
        </div>
    );
};

Snackbar.propTypes = {
    open: PropTypes.bool,
    vertical: PropTypes.oneOf(["top", "center", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
    severity: PropTypes.oneOf(["success", "error", "warning", "info"]),
    variant: PropTypes.oneOf(["outlined", "filled"]),
    message: PropTypes.string,
    disableAutoHide: PropTypes.bool,
};
Snackbar.defaultProps = {
    vertical: "bottom",
    horizontal: "right",
    severity: "success",
    disableAutoHide: false,
};

export default Snackbar;
