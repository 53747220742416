import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography } from '@mui/material';
import Actions from './Actions';
import ShimmerLoader from "./ShimmerLoader.jsx";


export default function TableComponent(props) {

  const [searchText, setSearchText] = React.useState("");
  const [dataset, setDataSet] = React.useState(props?.dataSet || []);
  // const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  React.useEffect(() => {
    const filteredRows = props?.dataSet?.filter((a) => {
      return JSON.stringify(a).toLowerCase().includes(searchText.toLowerCase());
    })
    setDataSet(filteredRows);
  }, [searchText, props?.dataSet])

  return (
    <div style={{ margin: "10px" }}>
      <Grid container >

        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={5} md={5} lg={5} style={{ marginBottom: "4px" }}>
              {
                props.tableHeading && <Typography variant='p' fontWeight='fontWeightMedium'>{props.tableHeading}</Typography>
              }
            </Grid>
            <Grid item xs={7} md={7} lg={7}>
              {
                props.tableActions && <Actions actions={props.tableActions} reverseRow={true} />
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12} style={{ marginBottom: "3px" }}>
          <TextField
            id="outlined-required"
            data-testid={"table-search-field"}
            fullWidth
            label={"Search.."}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Grid>


        <Grid item xs={12} md={12} lg={12}>
          {props?.loadingData && <ShimmerLoader type={"table"} />}
          {!props?.loadingData &&
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              checkboxSelection={props.checkboxSelection}
              onRowSelectionModelChange={props?.onRowSelectionModelChange}
              isRowSelectable={props?.isRowSelectable}
              rowSelectionModel={props?.rowSelectionModel}
              disableRowSelectionOnClick
              showCellRightBorder={true}
              rows={dataset || []}
              columns={props?.columns || []}
            />}
        </Grid>


      </Grid>

    </div>
  );
}