import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getGroups = createAsyncThunk(
    "getGroups",
    async (rejectWithValue) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}`,
                method: HTTP_METHODS.GET,
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getGroupDetails = createAsyncThunk(
    "getGroupDetails",
    async (groupName, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}/${groupName}`,
                method: HTTP_METHODS.GET,
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getGroupMembers = createAsyncThunk(
    "getGroupMembers",
    async (groupName, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}/${groupName}/members`,
                method: HTTP_METHODS.GET,
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateGroup = createAsyncThunk(
    "updateGroup",
    async ({ groupNameParam, payload }, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}/${groupNameParam}/members`,
                method: HTTP_METHODS.PATCH,
                data: payload
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createGroup = createAsyncThunk(
    "createGroup",
    async (payload, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}`,
                method: HTTP_METHODS.POST,
                data: payload
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteGroup = createAsyncThunk(
    "deleteGroup",
    async (payload, { rejectWithValue }) => {
        const selectedCustomer = sessionStorage.getItem("selectedCustomer");
        try {
            const response = await new HTTPClient({
                endpoint: `/groups/${selectedCustomer}/${payload.name}`,
                method: HTTP_METHODS.DELETE
            }).callAuthorizedAPI();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const initialState = {
    groups: [],
    loadingGroups: null,
    error: "",
    createSuccess: null,
    createError: null,

    groupDetails: {},
    loadingGroupDetails: null,
    groupDetailsError: null,

    deletingGroup: null,
    deleteSuccess: null,
    deleteError: null,

    groupMembers: [],
    loadingGroupMembers: null,
    groupMembersError: null,

    updatingGroup: null,
    updateGroupSuccess: null,
    updateGroupError: null,

    creatingGroup: null,
    createGroupSuccess: null,
    createGroupError: null,
    groupName: null,
};

export const groupsSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.loadingGroups = null;
            state.error = "";
            state.createSuccess = null;
            state.createError = null;
            state.groupDetailsError = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.loadingGroupMembers = null;
            state.groupMembersError = null;
            state.updatingGroup = null;
            state.updateGroupSuccess = null;
            state.updateGroupError = null;
            state.creatingGroup = null;
            state.createGroupError = null;
            state.createGroupSuccess = null;
            state.groupMembers = null;
            state.groupName = null;
        },
        setGroupName: (state, action) => {
            state.groupName = action.payload;
        },
        setGroups: (state, action) => {
            state.groups = action.payload;
        }

    },
    extraReducers: {
        [getGroups.pending]: (state, action) => {
            state.loadingGroups = true;
            state.currentRequestId = action.meta.requestId;
        },
        [getGroups.fulfilled]: (state, action) => {

            state.currentRequestId = null;
            let data = []
            let count = 0;
            if (action.payload) {
                count = 0;
                action.payload.map((a) => {
                    data.push({ ...a, id: count });
                    count++;
                })
            }
            state.groups = action.payload ? data : [];
            state.loadingGroups = false;
        },
        [getGroups.rejected]: (state, action) => {
            state.error = action.payload;
            state.loadingGroups = false;
            state.currentRequestId = null;
        },

        [getGroupDetails.pending]: (state, action) => {
            state.loadingGroupDetails = true;
            state.currentRequestId = action.meta.requestId;
        },
        [getGroupDetails.fulfilled]: (state, action) => {
            state.groupDetails = action.payload ? action.payload : {};
            state.loadingGroupDetails = false;
        },
        [getGroupDetails.rejected]: (state, action) => {
            state.groupDetailsError = action.payload;
            state.loadingGroupDetails = false;
            state.currentRequestId = null;
        },
        [getGroupMembers.pending]: (state, action) => {
            state.loadingGroupMembers = true;
            state.currentRequestId = action.meta.requestId;
        },
        [getGroupMembers.fulfilled]: (state, action) => {
            let data = []
            let count = 0;
            if (action.payload && action.payload.users) {
                count = 0;
                action.payload.users.map((a) => {
                    data.push({ ...a, id: count });
                    count++;
                })
            }
            state.groupMembers = action.payload?.users ? data : [];
            state.loadingGroupMembers = false;
        },
        [getGroupMembers.rejected]: (state, action) => {
            state.groupMembersError = action.payload;
            state.loadingGroupMembers = false;
            state.currentRequestId = null;
        },
        [updateGroup.pending]: (state, action) => {
            state.updatingGroup = true;
            state.currentRequestId = action.meta.requestId;
        },
        [updateGroup.fulfilled]: (state, action) => {
            // state.groupMembers = action.payload ? action.payload : {};
            state.updatingGroup = false;
            state.updateGroupSuccess = true;
            state.currentRequestId = null;
        },
        [updateGroup.rejected]: (state, action) => {
            state.updateGroupError = action.payload;
            state.updatingGroup = false;
            state.currentRequestId = null;
        },
        [createGroup.pending]: (state, action) => {
            state.creatingGroup = true;
            state.currentRequestId = action.meta.requestId;
        },
        [createGroup.fulfilled]: (state, action) => {
            // state.groupMembers = action.payload ? action.payload : {};
            state.creatingGroup = false;
            state.createGroupSuccess = true;
            state.currentRequestId = null;
        },
        [createGroup.rejected]: (state, action) => {
            state.createGroupError = action.payload;
            state.creatingGroup = false;
            state.currentRequestId = null;
        },
        [deleteGroup.pending]: (state, action) => {
            state.deletingGroup = true;
            state.currentRequestId = action.meta.requestId;
        },
        [deleteGroup.fulfilled]: (state, action) => {
            // state.groupMembers = action.payload ? action.payload : {};
            state.deletingGroup = false;
            state.deleteSuccess = true;
            state.currentRequestId = null;
        },
        [deleteGroup.rejected]: (state, action) => {
            state.deleteError = action.payload;
            state.deletingGroup = false;
            state.currentRequestId = null;
        }

    },
});

export const { reset, setGroupName, setGroups } = groupsSlice.actions;

export default groupsSlice.reducer;
