import { Skeleton } from '@mui/material'
import React from 'react'

const TableSkeleton = () => {
  return (
    <>
    <Skeleton style={{padding:"10px"}} variant={"rectangular"} width="98%" height={20}>
    </Skeleton>
    
    <Skeleton style={{padding:"10px", marginTop:"10px"}} variant={"rectangular"} width="98%" height={20}>
    </Skeleton>
    <Skeleton style={{padding:"10px", marginTop:"10px"}} variant={"rectangular"} width="98%" height={20}>
    </Skeleton>
    <Skeleton style={{padding:"10px", marginTop:"10px"}} variant={"rectangular"} width="98%" height={20}>
    </Skeleton>
    <Skeleton style={{padding:"10px", marginTop:"10px"}} variant={"rectangular"} width="98%" height={20}>
    </Skeleton>

  
    </>
  )
}

export default TableSkeleton