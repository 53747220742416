export const SPA_CLIENT_ID = process.env.REACT_APP_AD_SPA_CLIENT_ID;
export const AD_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const CALLBACK_REDIRECT_URI = process.env.REACT_APP_AD_REDIRECT_URL;
export const CORE_API_URL = process.env.REACT_APP_CORE_API_URL;
export const SNACKBAR_DURATION_MS = 6000;

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH"
};