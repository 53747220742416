import React from 'react'
import TabsComponent from './TabsComponent';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

const ManagementWrapper = (props) => {
  var customerCode = sessionStorage.getItem("selectedCustomer");
  const urlParams = useParams();
  if (customerCode.length === 0 && urlParams?.customerCode) {
    customerCode = urlParams?.customerCode;
    sessionStorage.setItem("selectedCustomer", customerCode);
  }
  const tabData = [
    {
      label: "User Management",
      value: "User Management",
      routeTo: `/users/${customerCode}`,
      handler: () => {
        console.log("Users")
      }
    },
    {
      label: "Group Management",
      value: "Group Management",
      routeTo: `/groups/${customerCode}`,
      handler: () => {
        console.log("Groups");
      }
    },
    {
      label: "Folder Management",
      value: "Folder Management",
      routeTo:  `/folders/${customerCode}`,
      handler: () => {
        console.log("Folder");
      }
    },
  ];
  return (

    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <TabsComponent
          setTab={props?.tabValue}
          tabData={tabData}
          defaultValue={"User Management"}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12} xl={12}>
        {
          props.component ? props.component : <></>
        }
      </Grid>

    </Grid>
  )
}

export default ManagementWrapper