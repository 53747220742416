import { Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomerDetails from './CustomerDetails'
import AddUsers from '../Users/AddUsers'
import ImportUsers from '../Users/ImportUsers'
import AddUsersTabWrapper from '../Users/AddUsersTabWrapper'
import { useDispatch } from 'react-redux'
import { createCustomer, getCustomerDeploymentCodes, reset } from './customerSlice'
import LoadingModal from '../common/LoadingModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import UserList from '../Users/UserList'
import Snackbar from '../common/Snackbar';
import { SNACKBAR_DURATION_MS } from "../../common/constants";




const CreateCustomer = () => {

  const { creatingCustomer, createCustomerSuccess, createCustomerError } = useSelector(
    (state) => state.customers
  );


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState("Add Users");
  const [usersData, setUsersData] = useState([]);

  const [customerName, setCustomerName] = useState();
  const [customerCode, setCustomerCode] = useState();
  const [deploymentCode, setDeploymentCode] = useState("");
  const [windowsSharePath, setWindowsSharePath] = useState();

  useEffect(() => {
    if (createCustomerSuccess) {
      navigate("/home");
    }
    setTimeout(() => {
      dispatch(reset());
    }, SNACKBAR_DURATION_MS);
  }, [createCustomerSuccess])


  const handleSubmitCustomer = () => {
    const payload = {
      customerCode,
      customerName,
      dataSharePath: windowsSharePath,
      users: usersData.map((a) => {
        return {
          userPrincipalName: a.userName,
          firstName: a.firstName,
          lastName: a.lastName,
          telephoneNumber: a.phoneNo ? a.phoneNo.toString() : "",
          displayName: a.displayName,
          password: a.password,
        }
      })
    }
    console.log(deploymentCode, payload);
    dispatch(createCustomer({ deploymentCode, payload }));
  }

  useEffect(() => {
    dispatch(getCustomerDeploymentCodes());
  }, [])

  return (
    <Grid>
      <Snackbar
        open={createCustomerError}
        severity="error"
        message={
          createCustomerError?.title
            ? createCustomerError?.title
            : "Unable to Create"
        }
      />

      <LoadingModal
        loaderText={"Please wait.."}
        open={creatingCustomer}
      />

      <CustomerDetails
        customerName={customerName}
        customerCode={customerCode}
        deploymentCode={deploymentCode}
        windowsSharePath={windowsSharePath}
        setCustomerName={setCustomerName}
        setCustomerCode={setCustomerCode}
        setDeploymentCode={setDeploymentCode}
        setWindowsSharePath={setWindowsSharePath}
      />

      <AddUsersTabWrapper
        componentA={<AddUsers usersData={usersData} setUsersData={setUsersData} customerCode={customerCode} />}
        componentB={<ImportUsers usersData={usersData} setUsersData={setUsersData} setTabValue={setTabValue} />}
        tabValue={tabValue}
        onTabChange={setTabValue} />


      <UserList usersData={usersData} setUsersData={setUsersData} />

      <Grid item style={{ display: "flex", flexDirection: "row-reverse", marginRight: "10px" }}>
        <Button variant="contained" disabled={customerName && deploymentCode ? false : true} onClick={handleSubmitCustomer}>Submit Customer</Button>
      </Grid>


    </Grid>
  )
}

export default CreateCustomer