import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { addkeyToObject } from "../../common/utils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getFolders = createAsyncThunk(
  "getFolders",
  async (customerCode, { rejectWithValue }) => {
    try {
      const response = await new HTTPClient({
        endpoint: `/sharedFolders/${customerCode}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      return { data: response.data, customerCode };
    } catch (error) {
      console.log("Error");
      return rejectWithValue(error.response.data)
    }
  }
);

export const applyPermissions = createAsyncThunk(
  "applyPermissions",
  async (payload, { rejectWithValue }) => {
    try {
      const customerCode = sessionStorage.getItem("selectedCustomer");
      const response = await new HTTPClient({
        endpoint: `/sharedFolders/${customerCode}/permissions`,
        method: HTTP_METHODS.PATCH,
        data: payload
      }).callAuthorizedAPI();

      return { data: response.data, customerCode };
    } catch (error) {
      console.log("Error");
      return rejectWithValue(error.response.data)
    }
  }
);


function addslashes(str) {
  return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
}

export const getPermissionsAndNextFolder = createAsyncThunk(
  "getPermissionsAndNextFolder",
  async ({ sharedFolderPath, useCache }, { getState, rejectWithValue }) => {
    try {
      const customerCode = sessionStorage.getItem("selectedCustomer");
      const folders = getState().folders.folders;
      if (useCache) {
        return { path: sharedFolderPath, currentFolders: folders[`${customerCode}`] };
      } else {
        // let x = addslashes(sharedFolderPath);
        let url = `/sharedFolders/${customerCode}/folders?sharedFolderPath=${sharedFolderPath}`
        console.log(url);
        const response = await new HTTPClient({
          endpoint: url,
          method: HTTP_METHODS.GET,
        }).callAuthorizedAPI();
        console.log("response", response);
        return { data: response.data, customerCode, path: sharedFolderPath, currentFolders: folders[`${customerCode}`] };
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
);



export const initialState = {
  folders: {},
  loadingFolders: null,
  getFoldersError: null,

  loadingFolderPermissions: null,
  permissions: {},
  errorPermissionsAndNextFolder: null,

  applyPermissionsLoading: null,
  applyPermissionsSuccess: null,
  applyPermissionsError: null,
};

export const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    reset: (state, action) => {
      state.getFoldersError = null;
      state.applyPermissionsLoading = null;
      state.applyPermissionsSuccess = null;
      state.applyPermissionsError = null;
    },
  },
  extraReducers: {
    [getFolders.pending]: (state, action) => {
      state.loadingFolders = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getFolders.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      if (action.payload) {
        state.folders[action.payload.customerCode] = action.payload.data?.map((a) => {
          return { ...a, id: a.path, children: [{ id: "1" }] }
        });
      }
      state.loadingFolders = false;
      state.getFoldersError = null;
    },
    [getFolders.rejected]: (state, action) => {
      state.getFoldersError = action.payload;
      state.loadingFolders = false;
      state.currentRequestId = null;
    },
    [applyPermissions.pending]: (state, action) => {
      state.applyPermissionsLoading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [applyPermissions.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      // if (action.payload) {
      //   state.folders[action.payload.customerCode] = action.payload.data?.map((a) => {
      //     return { ...a, id: a.path, children: [{ id: "1" }] }
      //   });
      // }
      state.applyPermissionsLoading = false;
      state.applyPermissionsSuccess = true;
    },
    [applyPermissions.rejected]: (state, action) => {
      state.applyPermissionsLoading = false;
      state.applyPermissionsError = action.payload;
      state.currentRequestId = null;
    },

    [getPermissionsAndNextFolder.pending]: (state, action) => {
      state.loadingFolderPermissions = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getPermissionsAndNextFolder.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      if (!action?.meta?.arg?.useCache && action.payload) {
        // if (action.payload.data.length > 1) {
        let currentFoldersData = action.payload.currentFolders;
        currentFoldersData = addkeyToObject(
          currentFoldersData,
          action.payload.path,
          'permissions',
          'children',
          action.payload.data[0],
          action.payload.data.splice(1)
        );
        state.folders[sessionStorage.getItem('selectedCustomer')] = currentFoldersData;
        // }
      }
      state.errorPermissionsAndNextFolder = null;
      state.loadingFolderPermissions = false;
    },
    [getPermissionsAndNextFolder.rejected]: (state, action) => {
      state.errorPermissionsAndNextFolder = action.payload;
      state.loadingFolderPermissions = false;
      state.currentRequestId = null;
    },

  },
});

export const { reset } = foldersSlice.actions;

export default foldersSlice.reducer;
