import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const TabsComponent = (props) => {

  const [value, setValue] = React.useState(props?.defaultValue);
  const navigate = useNavigate();


  useEffect(() => {
    if (props?.setTab) {
      setValue(props?.setTab);
    }
  }, [props])


  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.tabData?.map((a) => {
      if (a.value === newValue) {
        if (a.routeTo) {
          navigate(a?.routeTo);
        }
        if (a.handler) {
          a.handler();
        }

      }
    })
  };
  const handleClick = () =>{
    const selectedTab =  props?.tabData.filter((a) =>{
      return a.value === value;
    });
    if(selectedTab[0]?.routeTo){
    navigate(selectedTab[0].routeTo);
  }
}
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        onClick={()=> {
          handleClick();
        }}
        onChange={handleChange}
        value={value}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
      >
        {
          props?.tabData.map((a) => (
            <Tab label={a.label} value={a.value} data-testid={`Tab_${a.label}`}/>
          ))
        }
      </Tabs>


    </Box>
  );
}

export default TabsComponent