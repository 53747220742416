import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

const SelectField = (props) => {
    const {label, showNone, options, onChange, value, defaultValue, datatestid} = props;
    
    return (
    <div style={{display:"flex", flexDirection:"column"}}>
      
    <div style={{padding:"5px"}}>
    <Typography variant="p" color="#1a8aff">{label}</Typography>
    </div>

      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label"></InputLabel>
        <Select
          value={value}
          data-testid={datatestid}
          defaultValue={defaultValue}
          variant="outlined"
          onChange={onChange}
        >
          
         {showNone && <MenuItem value="">
            <em>None</em>
          </MenuItem>
          } 
          
          {
            options && options.map((a) =>{
                return <MenuItem data-testid={datatestid+"-"+a.value} value={a.value}>{a.name}</MenuItem>
            })
          }

        </Select>
      </FormControl>
    </div>
    );
}

export default SelectField