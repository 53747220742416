export const decodeToken = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


export const addkeyToObject = (obj, path, key1, key2, value1, value2, node) => {
    let someObj = [];
    obj.forEach(a => {
        let elem = { ...a };
        if (path === elem?.path) {

            elem.expandedForChild = true;
            if (value1 && value1['permissions'].length > 0) {
                elem[`${key1}`] = value1['permissions'].map((b, index) => {
                    return { ...b, id: index }
                });
            }

            if (value2 && value2.length > 0) {
                elem[`${key2}`] = value2.map((b) => {
                    let c = b.path;
                    c = c.split('\\');
                    let name = c[c.length - 1];
                    return { ...b, name: name, id: b.path, children: [{ id: "1" }] }
                });
            }
        } else if (elem.children) {
            let modifiedObj2 = addkeyToObject(elem.children, path, key1, key2, value1, value2);
            elem[`children`] = modifiedObj2;
        }

        someObj.push(elem);
    });
    return someObj;
}