import SignInSide from "./components/SignInSide.jsx";
import SignInError from "./components/SignInError.jsx";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/Home.jsx";
import Users from "./components/Users/Users.jsx";
import CreateUser from "./components/Users/CreateUsers.jsx";
import EditUserSecret from "./components/Users/EditUserSecret.jsx";
import Groups from "./components/Groups/Groups.jsx";
import CreateCustomer from "./components/Customers/CreateCustomer.jsx";
import LoginCallback from "./components/LoginCallback.js";
import Header from "./components/common/ResponsiveAppBar.jsx";
import { ProtectedRoute } from "./components/common/ProtectedRoute.jsx";
import { AuthProvider } from "./common/contexts/AuthContext.js";
import GroupDetail from "./components/Groups/GroupDetail.jsx";
import Folders from "./components/Folders/Folders.jsx";
import ManagementWrapper from "./components/common/ManagementWrapper.jsx";
import FolderPermission from "./components/Folders/FolderPermission.jsx";
import EditCustomer from "./components/Customers/EditCustomer.jsx";
import UserDetails from "./components/Users/UserDetails.jsx";
import EditUser from "./components/Users/EditUser.jsx";
function App() {

  return (
    <AuthProvider>
      <Router>

        <ProtectedRoute component={<Header />} noLoginWrapper={true} />

        <Routes>

          {/* Auth Routes */}

          <Route path="/" element={
            <SignInSide />
          } />
          <Route path="/LoginError" element={
            <SignInError />
          } />

          <Route
            path="/callback"
            element={<LoginCallback />} />

          {/* Customer Routes */}

          <Route
            path="/home"
            element={<ProtectedRoute component={<Home />} />} />

          <Route
            path="/customer/action/create"
            element={<ProtectedRoute component={<CreateCustomer />} />}
          />

          <Route
            path="/customer/:customerCode/edit"
            element={<ProtectedRoute component={<EditCustomer />} />}
          />


          {/* User Routes */}

          <Route
            path="/users/:customerCode"
            element={<ProtectedRoute component={<ManagementWrapper component={<Users />} tabValue="User Management" />} />}
          />

          <Route
            path="/users/:customerCode/:userName/action/secret/edit"
            element={<ProtectedRoute component={<ManagementWrapper component={<EditUserSecret />} tabValue="User Management" />} />}
          />

          <Route
            path="/users/:customerCode/:userName/action/edit"
            element={<ProtectedRoute component={<ManagementWrapper component={<EditUser />} tabValue="User Management" />} />}
          />

          <Route
            path="/user/:customerCode/action/create"
            element={<ProtectedRoute component={<ManagementWrapper component={<CreateUser />} tabValue="User Management" />} />}
          />

          <Route
            path="/users/:userName/details"
            element={<ProtectedRoute component={<ManagementWrapper component={<UserDetails />} tabValue="User Management" />} />}
          />




          {/* Groups Routes */}

          <Route
            path="/groups/:customerCode"
            element={<ProtectedRoute component={<ManagementWrapper component={<Groups />} tabValue="Group Management" />} />}
          />
          <Route
            path="/groups/:customerCode/action/create"
            element={<ProtectedRoute component={<ManagementWrapper component={<GroupDetail purpose={"create"} />} tabValue="Group Management" />} />}
          />

          <Route
            path="/groups/:customerCode/:groupNameParam/details"
            element={<ProtectedRoute component={
              <ManagementWrapper component={<GroupDetail purpose={"detail"} />} tabValue="Group Management" />}
            />} />
          <Route
            path="/groups/:customerCode/:groupNameParam/action/edit"
            element={<ProtectedRoute component={
              <ManagementWrapper component={<GroupDetail purpose={"update"} />} tabValue="Group Management" />}
            />} />

          {/* Folder Routes */}

          <Route
            path="/folders/:customerCode"
            element={<ProtectedRoute component={<ManagementWrapper component={<Folders />} tabValue="Folder Management" />} />}
          />

          <Route
            path="/folders/:customerCode/permissions/:name"
            element={<ProtectedRoute component={<ManagementWrapper component={<FolderPermission />} tabValue="Folder Management" />} />}
          />

        </Routes>
      </Router>
    </AuthProvider>
  );
}



export default App;
