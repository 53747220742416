import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TableComponent from './common/TableComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers, setCustomers, deleteCustomer, reset, convertCustomer } from './Customers/customerSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from './common/ConfirmModal';
import { Grid, Button } from '@mui/material';
import Snackbar from './common/Snackbar';
import Actions from './common/Actions';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [convertModalOpen, setConvertModalOpen] = useState(false);
  const [successfulDeletion, setSuccessfulDeletion] = useState(false);
  const [selectedCustomerToDelete, setSelectedCustomerToDelete] = useState();
  const [selectedCustomerToConvert, setSelectedCustomerToConvert] = useState();
  const { loadingCustomers, createCustomerSuccess, customers, deleteCustomerSuccess, deleteCustomerError, loadingDelete, error, loadingConvert, convertSuccess, convertError } = useSelector(
    (state) => state.customers
  );




  const columns = [
    {
      field: 'name', headerName: 'Customer Name', width: 300, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'Customer Name '}
        </strong>),
      renderCell: (params) => (
        <Button data-testid={`${params.row.name}-customer`} disabled={!params.row.isCascadeCustomer} onClick={() => {
          console.log(params.row.code);
          sessionStorage.setItem("selectedCustomer", `${params.row.code}`);
          navigate(`/users/${params.row.code}`);
        }}>
          {params.value}
        </Button>
      )
    },
    {
      field: 'code', headerName: 'Customer Code', width: 300, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'Customer Code'}
        </strong>)
    },
    {
      field: 'deploymentCode', headerName: 'Deployment Code', width: 300, sortable: true, renderHeader: (params) => (
        <strong>
          {'Deployment Code'}
        </strong>)
    },
    // {
    //   field: 'isCascadeCustomer', headerName: 'Cascade Customer', width: 300, sortable: false, renderHeader: (params) => (
    //     <strong>
    //       {'Cascade Customer'}
    //     </strong>)
    // },
    {
      field: 'actions', headerName: 'Actions', width: 300, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'Actions'}
        </strong>),
      renderCell: (params) => (
        <>

          <Actions
            actions={[
              {
                title: (
                  <span style={{ display: "flex", marginRight: "5px" }}>
                    <DeleteIcon color="secondary" style={{ cursor: "pointer" }} />
                  </span>
                ),
                handler: () => {
                  setSelectedCustomerToDelete(params.row);
                  setDeleteModalOpen(true);
                },
                "data-testid": "delete-customer",
                hide: !params.row.isCascadeCustomer
              },
              {
                title: (
                  <span style={{ display: "flex", marginRight: "5px" }}>
                    <AddToQueueIcon color="secondary" style={{ cursor: "pointer" }} />
                  </span>
                ),
                handler: () => {
                  setSelectedCustomerToConvert(params.row);
                  setConvertModalOpen(true);
                },
                "data-testid": "delete-customer",
                hide: params.row.isCascadeCustomer
              }
            ]}
          />
        </>
      )
    }
  ];

  React.useEffect(() => {
    dispatch(getCustomers());
  }, []);

  React.useEffect(() => {
    if (deleteCustomerSuccess === true) {
      const filteredData = customers.filter((a) => {
        return a.id !== selectedCustomerToDelete?.id;
      });
      dispatch(setCustomers(filteredData));
      setDeleteModalOpen(false);
      setSelectedCustomerToDelete(null);
      setSuccessfulDeletion(true);
    } else {
      setSuccessfulDeletion(false);
    }
    setTimeout(() => {
      dispatch(reset());
    }, 2000);
  }, [deleteCustomerError, deleteCustomerSuccess]);

  React.useEffect(() => {
    if (convertSuccess === true) {
      const updatedCustomers = customers.map((a) => {
        if (a.id == selectedCustomerToConvert?.id) {
          return { ...a, isCascadeCustomer: true };
        } else {
          return a;
        }
      });
      dispatch(setCustomers(updatedCustomers))
    }
    setConvertModalOpen(false);
    setSelectedCustomerToConvert(null);
    setTimeout(() => {
      dispatch(reset());
    }, 2000);
  }, [convertSuccess, convertError]);

  const handleDelete = async (deleteCust) => {
    if (deleteCust) {
      await dispatch(
        deleteCustomer(selectedCustomerToDelete)
      );
    } else {
      setDeleteModalOpen(false);
    }
  };

  const handleConvert = async (convertCust) => {
    if (convertCust) {
      await dispatch(
        convertCustomer(selectedCustomerToConvert)
      );
    } else {
      setConvertModalOpen(false);
    }
  };

  return (
    <Grid>
      <Snackbar
        open={deleteCustomerError}
        severity="error"
        message={
          deleteCustomerError?.detail
            ? deleteCustomerError?.detail
            : deleteCustomerError?.message
              ? deleteCustomerError?.message
              : "Unable to Create"
        }
      />
      <Snackbar
        open={convertError}
        severity="error"
        message={
          convertError?.detail
            ? convertError?.detail
            : convertError?.message
              ? convertError?.message
              : "Unable to Convert customer"
        }
      />
      <Snackbar
        open={createCustomerSuccess}
        severity="success"
        message={
          "Successfully created Customer"
        }
      />
      <Snackbar
        open={convertSuccess}
        severity="success"
        message={
          "Successfully Converted Customer"
        }
      />
      <ConfirmModal
        buttonLoading={loadingDelete}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={"Delete Customer"}
        verificationString={selectedCustomerToDelete?.name}
        description={`Are you sure you want to delete ${selectedCustomerToDelete?.name} ?`}
        placeholderLabel={`Type ${selectedCustomerToDelete?.name} to confirm..`}
        onSubmitHandler={handleDelete}
        primaryLoading={loadingDelete}
      />
      <ConfirmModal
        buttonLoading={loadingConvert}
        open={convertModalOpen}
        setOpen={setConvertModalOpen}
        title={"Convert Customer"}
        verificationString={selectedCustomerToConvert?.name}
        description={`Are you sure you want to Convert ${selectedCustomerToConvert?.name} ?`}
        placeholderLabel={`Type ${selectedCustomerToConvert?.name} to confirm..`}
        onSubmitHandler={handleConvert}
        primaryLoading={loadingConvert}
      />

      <Box sx={{ width: '100%' }}>
        <TableComponent
          columns={columns}
          dataSet={customers}
          loadingData={loadingCustomers}
          tableHeading={"Customers"}
          tableActions={[
            {
              title: (
                <span data-testid={"add-customer-url"} style={{ display: "flex", marginRight: "5px" }}>
                  {"Add Customer"}
                </span>
              ),
              handler: () => {
                navigate("/customer/action/create");
              },
              "data-testid": "add-new-customer",
            }
          ]}
        />
      </Box>
    </Grid>
  );
}