import * as React from "react";
import { fetchSessionItem } from "../../storage";

const AuthContext = React.createContext();

function authReducer(state, action) {
  switch (action.type) {
    case "authenticate": {
      return { isAuthenticated: true };
    }
    case "unAuthenticate": {
      return { isAuthenticated: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

function AuthProvider({ children }) {
  // if Token exists in Session storage and not expired then set isAuthenticated else unset.
  let token = fetchSessionItem("access_token");
  let token_expiry = fetchSessionItem("expires_on");
  
  let isAuthenticated = false;

  if(token && token_expiry){
    const currentTime = Math.floor(Date.now() / 1000);
    if(currentTime <= parseInt(token_expiry)){
      isAuthenticated = true;
    }
  }

  const [state, dispatch] = React.useReducer(authReducer, { isAuthenticated });
  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export { AuthProvider, useAuthContext };


