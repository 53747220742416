import { Accordion, AccordionSummary, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { Link, useNavigate, useParams } from 'react-router-dom'
import TableComponent from '../common/TableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupMembers } from './groupsSlice';
import { updateGroup } from './groupsSlice';
import InputField from '../common/InputField'
import { createGroup, reset, setGroupName } from './groupsSlice';
import LoadingModal from '../common/LoadingModal';
import Snackbar from '../common/Snackbar';
import Actions from '../common/Actions';

const all_members_columns = [
  {
    field: 'userPrincipalName', headerName: 'User Name', width: 500, sortable: false,
    renderHeader: (params) => (
      <strong>
        {'User Name '}
      </strong>),
    renderCell: (params) => (
      <>
        {params.value}
      </>
    )
  },
  {
    field: 'firstName', headerName: 'First Name', width: 300, sortable: false,
    renderHeader: (params) => (
      <strong>
        {'First Name '}
      </strong>),
  },
  {
    field: 'lastName', headerName: 'Last Name', width: 300, sortable: false,
    renderHeader: (params) => (
      <strong>
        {'Last Name '}
      </strong>),
  },
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    headerName: "Hello",
    width: 100,
  },
];


const GroupDetail = (props) => {
  const { purpose } = props
  const { customerCode, groupNameParam } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector((state => state.users));
  const { groupName, groups, groupMembers, loadingGroupMembers, groupMembersError, creatingGroup, createGroupError, createGroupSuccess, updatingGroup, updateGroupError, updateGroupSuccess } = useSelector((state => state.groups));
  const [allMembersList, setAllMembersList] = useState(users?.map((user) => { return { ...user, isEnabled: true } }) || []);
  const [currentMembersList, setCurrentMembersList] = useState(groupMembers || []);
  const [deletedMembersList, setDeletedMembersList] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [groupDescription, setGroupDescription] = useState('');

  useEffect(() => {
    if (groupNameParam) {
      groups.forEach((a) => { if (a.name === groupNameParam) setGroupDescription(a.description) });
      dispatch(setGroupName(groupNameParam));
      dispatch(getGroupMembers(groupNameParam));
    } else {
      setCurrentMembersList([]);
    }
  }, [groupNameParam])

  useEffect(() => {
    if (loadingGroupMembers === false) {
      setCurrentMembersList(groupMembers || []);
    }
  }, [loadingGroupMembers])


  useEffect(() => {
    if (allMembersList?.length > 0 && currentMembersList?.length > 0) {
      const currentMembersUserName = [];
      currentMembersList.forEach((a) => {
        currentMembersUserName.push(a?.userPrincipalName);
      });

      const currentMembersUserNameSet = new Set(currentMembersUserName);
      setAllMembersList(allMembersList.map((a) => {
        if (currentMembersUserNameSet.has(a?.userPrincipalName)) {
          return { ...a, isEnabled: false }
        } else {
          return { ...a }
        }
      }))

    }
  }, [allMembersList, currentMembersList])


  const members_columns = [
    {
      field: 'userPrincipalName', headerName: 'User Name', width: 500, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'User Name '}
        </strong>),
      renderCell: (params) => (
        <Link to={`/users/${params.value}`}>
          {params.value}
        </Link>
      )
    },
    {
      field: 'firstName', headerName: 'First Name', width: 300, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'First Name '}
        </strong>),
    },
    {
      field: 'lastName', headerName: 'Last Name', width: 300, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'Last Name '}
        </strong>),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      sortable: false,
      hidden: true,
      renderCell: (params) => (
        <>

          <Actions
            actions={[
              {
                title: (
                  <span style={{ display: "flex", marginRight: "5px" }}>
                    <DeleteIcon color="secondary" style={{ cursor: "pointer" }} />
                  </span>
                ),
                handler: () => {
                  setAllMembersList(allMembersList.map((a) => {
                    if (a.userName === params.row.userName) {
                      if (a.new === true) {
                        return { ...a, isEnabled: true };
                      } else {
                        return { ...a, isEnabled: true, deleted: true };
                      }
                    } else {
                      return { ...a };
                    }
                  }))
                  if (currentMembersList && currentMembersList?.length > 0) {
                    setCurrentMembersList(currentMembersList.filter((a) => {
                      return a.id !== params.row.id;
                    }));
                    setDeletedMembersList(currentMembersList.filter((a) => {
                      return a.id === params.row.id;
                    }));
                  }
                },
                "data-testid": "delete-customer",
                hide: purpose === "detail"
              },
            ]}
          />
        </>
      )
    }
  ];

  const handleUpdateGroup = () => {
    const payload = {
      addMembers: currentMembersList.filter((currentMember) => { return currentMember.new === true }).map((currentMember) => { return currentMember.samAccountName }),
      deleteMembers: deletedMembersList.map((a) => { return a.samAccountName })
    };
    dispatch(updateGroup({ groupNameParam, payload }));
  }
  const handleSubmit = () => {
    const payload = {
      name: groupName,
      description: groupDescription,
      members: currentMembersList.map((currentMember) => { return currentMember.samAccountName })
    }
    dispatch(createGroup(payload));
  }

  useEffect(() => {
    if (updateGroupSuccess === true || createGroupSuccess === true) {
      navigate(`/groups/${customerCode}`);
    }
    dispatch(reset());
  }, [updateGroupSuccess, updateGroupError, createGroupError, createGroupSuccess])
  
  const purposeMessageSwitch = (purpose) => {
    switch (purpose) {
      case 'create':
        return 'Submit';
      case 'update':
        return 'Update Group';
      default:
        return 'Return to Groups';
    }
  }

  const purposeHandleSwitch = (purpose) => {
    switch (purpose) {
      case 'create':
        return handleSubmit();
      case 'update':
        return handleUpdateGroup();
      default:
        return navigate(`/groups/${customerCode}`);
    }
  }

  return (
    <div>
      <LoadingModal
        open={creatingGroup || updatingGroup || loadingGroupMembers}
        loaderText={"Please wait.."} />
      <Snackbar
        open={createGroupError}
        severity="error"
        message={
          createGroupError?.detail
            ? createGroupError?.detail
            : createGroupError?.message
              ? createGroupError?.message
              : "Unable to Create Group"
        }
      />
      <Snackbar
        open={updateGroupError}
        severity="error"
        message={
          updateGroupError?.detail
            ? updateGroupError?.detail
            : updateGroupError?.message
              ? updateGroupError?.message
              : "Unable to update Group"
        }
      />

      <Grid container style={{ marginLeft: "10px", marginTop: "10px" }} spacing={1}>
        <Grid item xs={5} md={5} lg={5} xl={5}>
          <InputField
            label={"Group Name"}
            value={groupName}
            onChange={(e) => {
              dispatch(setGroupName(e.target.value));
            }}
            disabled={purpose !== "create"}
          />
        </Grid>

        <Grid item xs={5} md={5} lg={5} xl={5}>
          <InputField
            label={"Group Description"}
            value={groupDescription}
            onChange={(e) => {
              setGroupDescription(e.target.value);
            }}
            disabled={purpose !== "create"}
          />
        </Grid>

      </Grid>

      <Grid>
        <TableComponent
          columns={members_columns}
          dataSet={currentMembersList || []}
          loadingData={loadingGroupMembers}
          tableHeading={"Members List"}
          tableActions={[
            {
              title: (
                <span style={{ display: "flex", marginRight: "5px" }}>
                  {purposeMessageSwitch(purpose)}
                </span>
              ),
              handler: () => {
                purposeHandleSwitch(purpose);
              },
              "data-testid": "submit_group",
              // hide: purpose === "detail"
            }
          ]}
        />
      </Grid>
      <Divider />



      <div style={{ margin: 10 }} hidden={purpose === "detail"}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="p" fontWeight={500}>Add Members</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid>
              <TableComponent
                checkboxSelection={true}
                columns={all_members_columns}
                dataSet={allMembersList || []}
                loadingData={false}
                onRowSelectionModelChange={(ids) => {
                  setSelectedMembers(ids);
                }}
                rowSelectionModel={selectedMembers}
                isRowSelectable={(params) => {
                  return params.row.isEnabled;
                }}
                tableHeading={"Find Members to add"}
                tableActions={[
                  {
                    title: (
                      <span style={{ display: "flex", marginRight: "5px" }}>
                        {"+ Add Members"}
                      </span>
                    ),
                    handler: () => {
                      const selectedIds = new Set(selectedMembers);
                      const modifiedCurrentMembersData = [...currentMembersList];
                      setAllMembersList(allMembersList.map((a) => {
                        if (selectedIds.has(a.id)) {
                          modifiedCurrentMembersData.push({ id: `${a.userPrincipalName}${a.id}`, samAccountName: a.samAccountName, userPrincipalName: a.userPrincipalName, firstName: a.firstName, lastName: a.lastName, new: true });
                          return { ...a, isEnabled: false }
                        } else {
                          return { ...a };
                        }
                      }));
                      setCurrentMembersList(modifiedCurrentMembersData);
                      setSelectedMembers([]);
                    },
                    "data-testid": "add-members-to-groups",
                  }
                ]}
              />
            </Grid>
            <Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>

      </div>
    </div >
  )
}

export default GroupDetail