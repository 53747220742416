import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TableComponent from '../common/TableComponent';
import { useNavigate, useParams } from 'react-router-dom';
import Snackbar from '../common/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups, reset, setGroups, deleteGroup } from './groupsSlice';
import LoadingModal from "../common/LoadingModal";
import { getUsers } from "../Users/usersSlice";
import { Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmModal from '../common/ConfirmModal';
import Actions from '../common/Actions';


export default function Groups() {
  const navigate = useNavigate();
  const { customerCode } = useParams();
  const dispatch = useDispatch();
  const { groups, loadingGroups, error, deletingGroup, deleteError, deleteSuccess, updateGroupSuccess } = useSelector((state => state.groups));
  const { users, loadingUsers } = useSelector((state => state.users));
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  React.useEffect(() => {
    dispatch(getGroups(customerCode));
    if (users?.length === 0) {
      dispatch(getUsers(customerCode));
    }
  }, []);

  React.useEffect(() => {
    if (deleteSuccess === true) {
      const filteredData = groups.filter((a) => {
        return a.id !== selectedGroupToDelete?.id;
      });
      dispatch(setGroups(filteredData));
    }
    setDeleteModalOpen(false);
  }, [deleteSuccess, deleteError]);

  const handleDelete = async (groupToDelete) => {
    if (groupToDelete) {
      await dispatch(
        deleteGroup(selectedGroupToDelete)
      );
    } else {
      setDeleteModalOpen(false);
    }
    setSelectedGroupToDelete(null);
    dispatch(reset());
  }

  const columns = [
    {
      field: 'name', headerName: 'Group Name', width: 300, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'Group Name '}
        </strong>),
      renderCell: (params) => (
        <Button onClick={() => { navigate(`/groups/${customerCode}/${params.value}/details`) }}>
          {params.value}
        </Button>
      )
    },
    {
      field: 'description', headerName: 'Group Description', width: 300, sortable: true,
    },
    {
      field: 'actions', headerName: 'Actions', width: 300, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'Actions'}
        </strong>),
      renderCell: (params) => (
        <>

          <Actions
            actions={[
              {
                title: (
                  <span style={{ display: "flex", marginRight: "5px" }}>
                    <DeleteIcon color="secondary" style={{ cursor: "pointer" }} />
                  </span>
                ),
                handler: () => {
                  setSelectedGroupToDelete(params.row);
                  setDeleteModalOpen(true);
                },
                "data-testid": "delete-group",
              },
              {
                title: (
                  <span style={{ display: "flex", marginRight: "5px" }}>
                    <EditIcon color="secondary" style={{ cursor: "pointer" }} />
                  </span>
                ),
                handler: () => {
                  navigate(`/groups/${customerCode}/${params.row.name}/action/edit`);
                },
                "data-testid": "edit-group",
              },
            ]}
          />
        </>
      )
    }
  ];



  return (
    <Grid>
      <Snackbar
        open={deleteSuccess}
        severity="success"
        message={
          `Successfully Deleted group ${selectedGroupToDelete?.name}`
        }
      />
      <Snackbar
        open={updateGroupSuccess}
        severity="success"
        message={
          `Successfully Updated group`
        }
      />
      <Snackbar
        open={error}
        severity="error"
        message={
          error?.detail
            ? error?.detail
            : error?.message
              ? error?.message
              : "Unable to Get Groups"
        }
      />
      <Snackbar
        open={deleteError}
        severity="error"
        message={
          deleteError?.detail
            ? deleteError?.detail
            : deleteError?.message
              ? deleteError?.message
              : "Unable to Delete Group"
        }
      />
      <LoadingModal
        open={loadingGroups || loadingUsers}
        loaderText={"Please wait.."} />
      <ConfirmModal
        buttonLoading={deletingGroup}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={"Delete Group"}
        verificationString={selectedGroupToDelete?.name}
        description={`Are you sure you want to delete ${selectedGroupToDelete?.name} ?`}
        placeholderLabel={`Type ${selectedGroupToDelete?.name} to confirm..`}
        onSubmitHandler={handleDelete}
        primaryLoading={deletingGroup}
      />
      <Box sx={{ width: '100%' }}>
        <TableComponent
          columns={columns}
          dataSet={groups}
          loadingData={false}
          tableHeading={"Groups"}
          tableActions={[
            {
              title: (
                <span style={{ display: "flex", marginRight: "5px" }}>
                  {"Add New Group"}
                </span>
              ),
              handler: () => {
                navigate(`/groups/${customerCode}/action/create`);
              },
              "data-testid": "add-new-group",
            }
          ]}
        />
      </Box>
    </Grid>
  );
}