import React, { Suspense, useCallback, useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuthContext } from "../../common/contexts/AuthContext";
import LoginWrapper from "../LoginWrapper";

export function ProtectedRoute({ component: Component, noLoginWrapper}) {
  const {state: { isAuthenticated }} = useAuthContext();

  const handleSignIn = () =>{
    console.log("Logic to Clear Session Storage and Route back to Login Page");
  }

  useEffect(() => {
    if (!isAuthenticated) {
      handleSignIn();
    }
  }, [isAuthenticated]);

  return (
    isAuthenticated ? (Component) : 
    (noLoginWrapper ? <></> : <LoginWrapper />)
    // <Route
    //   {...rest}
    //   element={(props) => {
    //     if (isAuthenticated) {
    //       return Component;
    //     } else {
    //       return <LoginWrapper />;
    //     }
    //   }}
    // />

  );
}

export const ProtectedRouteSuspenseContainer = ({ component: Component, ...rest }) => {
  return (
    <React.Fragment>
    <ProtectedRoute
      component={
        <Suspense fallback={"Loading..."}>
          <Component {...rest} />
        </Suspense>
      }
    />
    </React.Fragment>
  );
};
