import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "./constants.js";
import HTTPClient from "./servicers/httpClient.js";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const authApi = createAsyncThunk(
  "auth",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, auth } = getState().auth;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && auth) {
      return { data: auth };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/authenticate",
        method: HTTP_METHODS.POST,
      }).callUnauthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const initialState = {
  auth: null,
  requesting: false,
  loading: true,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload.data
    }
  },
  extraReducers: {
    [authApi.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [authApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.auth = action.payload ? (action.payload.data ? action.payload.data : null) : null;
    },
    [authApi.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
});

export const { setAuth } = auth.actions;

export default auth.reducer;
