import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useMsal } from "@azure/msal-react";
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuthContext } from '../../common/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ViewPointLogo from "../../viewpoint-logo-full-resize.svg";
import SelectField from './SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getCustomers } from '../Customers/customerSlice';
import { setSessionItem } from '../../storage';

export default function ResponsiveAppBar() {
  const menuId = 'primary-search-account-menu';
  const customerCode = sessionStorage.getItem("selectedCustomer");


  const { loadingCustomers, customers, error } = useSelector(
    (state) => state.customers
  );

  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { dispatch: dispatchAuth, state: { isAuthenticated } } = useAuthContext();
  const [customerName, setCustomerName] = useState('');


  const [customersData, setCustomersData] = useState();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };



  const handleLogout = () => {
    sessionStorage.clear();
    dispatchAuth({ type: "unAuthenticate" });
    navigate("/");
  };
  const handleRedirect = () => {
    navigate("/");
    if (sessionStorage.getItem('role') === 'Tenant Admins') {
      setSessionItem("selectedCustomer", "");
    }
    // if (sessionStorage.getItem('role') === 'Tenant Admins') {
    //   setCustomerName("All Customers");
    //   sessionStorage.setItem("selectedCustomer", "All Customers");
    // }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <Typography>{sessionStorage.getItem("name")}</Typography> */}
      {/* <MenuItem onClick={handleMenuClose} data-testid="my-Profile-Menu-item">My Profile</MenuItem> */}
      <MenuItem onClick={handleLogout} data-testid={"signout-menu-item"}>Sign out</MenuItem>
    </Menu>
  );

  React.useEffect(() => {
    dispatch(getCustomers());
  }, []);



  React.useEffect(() => {
    const c = [];
    // c.push({ name: "All Customers", value: "All Customers" });
    customers?.map((a) => {
      c.push({ name: a.name, value: a.name });
    })
    setCustomersData(c)
  }, [customers])


  React.useEffect(() => {
    const customer = customers?.filter((a) => {
      return a.code === customerCode;
    });
    if (customerCode == 'undefined') {
      setCustomerName("");
    } else {
      setCustomerName(customer[0]?.name);
    }
  }, [customerCode, customers])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="#1a8aff">
            <img src={ViewPointLogo} alt="ViewPointLogo" style={{ marginTop: "10px", cursor: "pointer" }} onClick={handleRedirect} />
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} color="#1a8aff">
            <Typography variant="h6" color="#3296ff" align='center' style={{ marginTop: "20px", marginRight: "20px", marginBottom: "20px" }}>{customerName}</Typography>
            {/* <SelectField 
           datatestid={"select-customers-field"}
            label={""}
            value={customerName}
            defaultValue="All Customers"
            options = {
              customersData || []}
            onChange={(e)=>{
              if(e.target.value === "All Customers"){
                sessionStorage.setItem("selectedCustomer", 'All Customers');
                navigate("/home");
               }
               setCustomerName(e.target.value);
               const customer = customers?.filter((a) =>{
                  return a.name === e.target.value;
               })
               if(customer[0]?.code){
                navigate(`/users/${customer[0]?.code}`);
                sessionStorage.setItem("selectedCustomer", customer[0]?.code);
               }
            }}
            /> */}


            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon data-testid={"account-icon"} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
