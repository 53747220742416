import { Skeleton } from '@mui/material';
import TableSkeleton from "./TableSkeleton.jsx";
import React from 'react'



const ShimmerLoader = (props) => {
  const {type} = props;
  return (
    <>
    {
      type == "table" && <TableSkeleton />
    }
    </>
  )
}

export default ShimmerLoader