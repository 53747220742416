import * as React from 'react';
import Box from '@mui/material/Box';
import TableComponent from '../common/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteUser, getUsers, reset } from './usersSlice';
import Snackbar from '../common/Snackbar';
import { Button, Grid } from '@mui/material'
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from '../common/ConfirmModal';
import { useState } from 'react';
import { SNACKBAR_DURATION_MS } from "../../common/constants";


export default function Users() {

  const { customerCode } = useParams();

  const [selectedUser, setSelectedUserToDelete] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCustomer = sessionStorage.getItem("selectedCustomer");
  const { loadingUsers, users, updatePasswordSuccess, getUsersError, deletingUser, deleteSuccess, deleteError, createSuccess } = useSelector(
    (state) => state.users
  );



  React.useEffect(() => {
    dispatch(getUsers());
  }, [selectedCustomer, deleteSuccess]);





  const columns = [
    {
      field: 'userPrincipalName', headerName: 'User Name', width: 500, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'User Name '}
        </strong>),
      renderCell: (params) => (
        <Button onClick={() => { navigate(`/users/${params.value}/details`) }}>
          {params.value}
        </Button>
      )
    },
    {
      field: 'firstName', headerName: 'First Name', width: 300, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'First Name '}
        </strong>),
    },
    {
      field: 'lastName', headerName: 'Last Name', width: 300, sortable: true,
      renderHeader: (params) => (
        <strong>
          {'Last Name '}
        </strong>),
    },
    {
      field: 'Actions', headerName: 'Actions', width: 300, sortable: false,
      renderHeader: (params) => (
        <strong>
          {'Actions'}
        </strong>),
      renderCell: (params) => (
        <>
          <LockResetIcon data-testid={"lock-reset-icon"} color="secondary" style={{ cursor: "pointer", padding: "2px" }} onClick={() => {
            navigate(`/users/${customerCode}/${params.row.userPrincipalName}/action/secret/edit`)
          }} />

          <DeleteIcon color="secondary" data-testid={"delete-icon"} style={{ cursor: "pointer", padding: "2px" }} onClick={() => {
            setSelectedUserToDelete(params.row);
            setDeleteModalOpen(true);
          }} />
          <EditIcon data-testid={"edit-user-icon"} color="secondary" style={{ cursor: "pointer", padding: "2px" }} onClick={() => {
            navigate(`/users/${customerCode}/${params.row.userPrincipalName}/action/edit`);
          }} />
        </>
      )
    },
  ];

  React.useEffect(() => {
    if (deleteSuccess === true) {
      setDeleteModalOpen(false);
    }
    setTimeout(() => {
      dispatch(reset());
    }, SNACKBAR_DURATION_MS);
  }, [deleteSuccess])


  const handleDelete = () => {
    dispatch(deleteUser(selectedUser?.userPrincipalName));
  }

  return (
    <Grid>
      <Snackbar
        open={getUsersError}
        severity="error"
        message={
          getUsersError?.message || "Unable to get Users"
        }
      />
      <Snackbar
        open={createSuccess}
        severity="success"
        message={
          "Successfully Created User. Please check the downloaded file for details"
        }
      />
      <Snackbar
        open={deleteSuccess}
        severity="success"
        message={
          "Successfully deleted user"
        }
      />
      <Snackbar
        open={updatePasswordSuccess}
        severity="success"
        message={
          "Successfully Updated Password"
        }
      />

      <Snackbar
        open={deleteError}
        severity="error"
        message={
          deleteError?.detail
            ? deleteError?.detail
            : deleteError?.message
              ? deleteError?.message
              : "Unable to Delete"
        }
      />


      <ConfirmModal
        buttonLoading={deletingUser}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={"Delete User"}
        verificationString={selectedUser?.displayName}
        description={`Are you sure you want to delete ${selectedUser?.displayName} ?`}
        placeholderLabel={`Type ${selectedUser?.displayName} to confirm..`}
        onSubmitHandler={handleDelete}
        primaryLoading={deletingUser}
      />


      <Box sx={{ width: '100%' }}>

        <TableComponent
          columns={columns}
          dataSet={users || []}
          loadingData={loadingUsers}
          tableHeading={"Users"}
          tableActions={[
            {
              title: (
                <span style={{ display: "flex", marginRight: "5px" }}>
                  {"Add User"}
                </span>
              ),
              handler: () => {
                navigate(`/user/${selectedCustomer}/action/create`);
              },
              "data-testid": "add-new-user",
            },
          ]}
        />
      </Box>
    </Grid>
  );
}